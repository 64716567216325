//This will be the component presented when a truck owners navigates to the orders/truck_id page. This page will
//display all of the orders for a specific truck. On this page there should be the ability to view the order details,
//mark an order as complete, and delete an order
//All orders shouold be displayed as a card, with all items and any notes or ingredients listed for each item in the order

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import CustomPopover from "../CustomPopover/CustomPopover";
import ViewOrderDetails from "../ManageTrucks/ViewOrderDetails";
import Loading from "../Loading/Loading";
import { Grid } from "@mui/material";
import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableContainer,
} from "@mui/material";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";

export default function TruckOrders(props) {
	const { truck_id } = useParams();
	const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
	const [orders, setOrders] = useState([]);
	const [orderInFocus, setOrderInFocus] = useState(null);
	const [formStatus, setFormStatus] = useState("idle");
	const [seconds, setSeconds] = useState(15);
	dayjs.extend(utc);

	const chronologicalSortOrders = (orders) => {
		return orders.sort((a, b) => {
			return new Date(a.created_at) - new Date(b.created_at);
		});
	};

	useEffect(() => {
		setFormStatus("loading");

		axios
			.get(process.env.REACT_APP_BACKEND_URL + `/getOrdersByTruck/${truck_id}`)
			.then((res) => {
				setOrders(chronologicalSortOrders(res?.data));
			})
			.then(() => {
				setFormStatus("idle");
			})
			.catch((error) => {
				setFormStatus("error");
				console.log(error);
			});
	}, [truck_id]);

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/orders/" + truck_id,
			title: "Truck Orders",
		});
	});

	//create a function that will refresh the orders every 15 seconds

	useEffect(() => {
		if (seconds > 0) {
			const intervalId = setInterval(() => {
				setSeconds((seconds) => seconds - 1);
			}, 1000);

			return () => clearInterval(intervalId); // This clears the interval when the component unmounts
		} else {
			setFormStatus("loading");
			setSeconds(15);
			axios
				.get(
					process.env.REACT_APP_BACKEND_URL + `/getOrdersByTruck/${truck_id}`
				)
				.then((res) => {
					setOrders(chronologicalSortOrders(res?.data));
					setFormStatus("idle");
				})
				.catch((error) => {
					setFormStatus("error");
					console.log(error);
				});
		}
	}, [seconds, truck_id]);

	const handleCompleteOrder = (order) => {
		//update the order status to "completed"
		setFormStatus("completing");
		const completeOrder = async () => {
			if (isAuthenticated && user) {
				const token = await getAccessTokenSilently();
				axios
					.post(
						process.env.REACT_APP_BACKEND_URL +
							`/markOrderCompleted/${order.id}`,
						{
							status: "Completed",
						},
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then((res) => {
						setFormStatus("completed");
						setTimeout(() => {
							setFormStatus("idle");
						}, 1000);
					})
					.catch((error) => {
						setFormStatus("error");
						console.log(error);
					});
			}
		};
		completeOrder();
	};

	const getPaidOrders = () => {
		const paidOrders = orders.filter((order) => {
			return order.status === "Paid";
		});

		return paidOrders;
	};

	return (
		<Box height={"100vh"}>
			<Helmet>
				<title>Truck Orders</title>

				<meta
					name="description"
					content="View and manage all orders for your truck"
				/>
			</Helmet>
			{orderInFocus ? (
				<CustomPopover setPopoverVisible={setOrderInFocus}>
					<ViewOrderDetails
						orderInFocus={orderInFocus}
						setOrderInFocus={setOrderInFocus}
					></ViewOrderDetails>
				</CustomPopover>
			) : null}

			<Box
				display={"flex"}
				flexDirection={"column"}
				justifyContent={"center"}
				alignItems={"center"}
			>
				<Typography variant={"h3"}>Orders</Typography>
				<Typography variant={"h6"}>
					Next refresh in: {seconds} seconds
				</Typography>

				<Box padding={2}>
					{formStatus === "loading" ? (
						<Loading />
					) : formStatus === "error" ? (
						<Typography variant={"h4"}>Error loading orders</Typography>
					) : (
						<Grid container>
							{getPaidOrders(orders).length > 0 ? (
								orders.map((order, index) => {
									//display the orders that are not completed first
									if (
										order.status !== "Completed" &&
										order.status !== "Created"
									) {
										return (
											//Disaplay the order as a card
											//with all the items listed in the order
											<Grid item xs={12} md={6} key={order.id} padding={2}>
												<Card
													//if the order is completed, set the background color to green
													sx={{
														backgroundColor:
															order.status === "Completed"
																? //rgba green with opacity
																  "rgba(0, 128, 0, 0.5)"
																: //rgpa yellow with opacity
																  "rgba(255, 255, 0, 0.5)",
													}}
												>
													<CardContent>
														<Box padding={1}>
															<Typography variant={"h4"}>
																Order Number: {order.id}
															</Typography>
															<Typography variant={"h5"}>
																Order Date:{" "}
																{
																	//dayjs formatted to MM/DD/YY hh:mm A with CST timezone
																	dayjs(order.created_at.substring(0, 23))
																		.utcOffset(6)
																		.format("MM/DD/YY hh:mm A")
																}
															</Typography>
															<Typography variant={"h5"}>
																Order Total: ${order.total}
															</Typography>
															<Typography variant={"h5"}>
																Order Status: {order.status}
															</Typography>

															<Box>
																<TableContainer>
																	<Table>
																		<TableHead>
																			<TableRow>
																				<TableCell>Item Name</TableCell>
																				<TableCell>Item Price</TableCell>
																				<TableCell>
																					Removed Ingredients
																				</TableCell>
																				<TableCell>Item Notes</TableCell>
																			</TableRow>
																		</TableHead>
																		<TableBody>
																			{order.items &&
																				order.items?.map((item, index) => {
																					const parsedItem = JSON.parse(item);
																					return (
																						<TableRow key={parsedItem.name}>
																							<TableCell>
																								{parsedItem.name}
																							</TableCell>
																							<TableCell>
																								{parsedItem.price}
																							</TableCell>
																							<TableCell>
																								{
																									//list all ingredients that are in the parsedItem.ingredients array but not in the parsedItem.selectedIngredients array
																									parsedItem.ingredients
																										.filter(
																											(ingredient) =>
																												!parsedItem.selectedIngredients.includes(
																													ingredient
																												)
																										)
																										.map((ingredient) => {
																											return (
																												<Box key={ingredient}>
																													<Typography
																														style={{
																															color: "red",
																														}}
																													>
																														{ingredient}
																													</Typography>
																												</Box>
																											);
																										})
																								}
																							</TableCell>
																							<TableCell>
																								{parsedItem.notes}
																							</TableCell>
																						</TableRow>
																					);
																				})}
																		</TableBody>
																	</Table>
																</TableContainer>
															</Box>
														</Box>
													</CardContent>

													{order.status === "Completed" ? null : (
														<Box padding={1}>
															<Button
																variant="contained"
																color="primary"
																onClick={() => {
																	handleCompleteOrder(order);
																}}
															>
																Complete Order
															</Button>
														</Box>
													)}
												</Card>
											</Grid>
										);
									} else {
										return null;
									}
								})
							) : (
								<Box
									padding={2}
									height={"50vh"}
									display={"flex"}
									justifyContent={"center"}
									alignItems={"center"}
								>
									<Typography variant={"h4"}>No orders to display</Typography>
								</Box>
							)}
						</Grid>
					)}
				</Box>
			</Box>
		</Box>
	);
}
