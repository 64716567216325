//This will be the customer menu page, where the user can see the current trucks menu, and add items to their cart

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Box, Button, TableContainer, Typography } from "@mui/material";

import { Card, CardMedia, CardContent } from "@material-ui/core";

import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from "@mui/material";
import Loading from "../Loading/Loading";

export default function CustomerMenu(props) {
	const { setAddToCartPopover, setAddToCartItem } = props;
	const { id } = useParams();
	const [menus, setMenus] = useState({});
	const [formStatus, setFormStatus] = useState("idle");
	const [selectedMenu, setSelectedMenu] = useState(null);

	useEffect(() => {
		setFormStatus("loading");
		axios
			.get(process.env.REACT_APP_BACKEND_URL + `/menus/${id}`)
			.then((res) => {
				setMenus(res?.data);
			})
			.then(() => {
				setFormStatus("idle");
			})
			.catch((error) => {
				setFormStatus("error");
				console.log(error);
			});
	}, [id]);

	useEffect(() => {
		console.log(selectedMenu);
	}, [selectedMenu]);

	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			alignItems={"center"}
			justifyContent={"center"}
		>
			{formStatus === "loading" ? (
				<Loading />
			) : (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{
						//if there is no menu, display a message that the shop is closed
						menus?.length === 0 && (
							<Typography variant="h2" gutterBottom>
								Shop is closed
							</Typography>
						)
					}
					{
						/*if there is more than one menu, display a table with the menu options */
						menus?.length > 1 && !selectedMenu && (
							<TableContainer>
								<Typography variant="h2" textAlign={"center"} gutterBottom>
									Menus
								</Typography>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell //make full width
											>
												Menu name
											</TableCell>
											<TableCell //make full width
											>
												Description
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{menus?.map((menu) => {
											//if the menu is active, display it in the table with a button to select it
											//if the menu is inactive, display it in the table with a message that the shop is closed
											if (menu.status === "Active") {
												return (
													<TableRow
														key={menu.menu_id}
														onClick={() => {
															setSelectedMenu(menu);
														}}
													>
														<TableCell>{menu.name}</TableCell>
														<TableCell>{menu.description}</TableCell>
													</TableRow>
												);
											} else if (menu.status === "Inactive") {
												return (
													<TableRow key={menu.menu_id}>
														<TableCell>{menu.name}</TableCell>
														<TableCell>This menu is closed</TableCell>
													</TableRow>
												);
											}
											return null;
										})}
									</TableBody>
								</Table>
							</TableContainer>
						)
					}

					{
						//if there is a selected menu, display the menu items in a table
						selectedMenu && (
							<Box>
								<Typography variant="h4" textAlign={"center"} gutterBottom>
									Menu ID: {selectedMenu?.menu_id}
								</Typography>

								<TableContainer>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell //make full width
												>
													Menu Items
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{selectedMenu?.menu_items.length > 0 ? (
												selectedMenu?.menu_items?.map((item) => {
													return (
														<TableRow key={item.menu_item_id}>
															<TableCell>
																<Card>
																	<CardMedia
																		component="img"
																		height="140"
																		image={item?.image_url}
																		alt={item?.name + " image" || "menu item"}
																	/>
																	<CardContent>
																		<Typography
																			gutterBottom
																			variant="h5"
																			component="div"
																		>
																			{item.name}
																		</Typography>
																		<Typography
																			variant="body2"
																			color="text.secondary"
																		>
																			{item.description}
																		</Typography>
																		<Typography
																			variant="body2"
																			color="text.secondary"
																		>
																			{item.price}
																		</Typography>
																		<Button
																			variant="contained"
																			onClick={() => {
																				setAddToCartPopover(true);
																				setAddToCartItem(item);
																			}}
																		>
																			Add to Cart
																		</Button>
																	</CardContent>
																</Card>
															</TableCell>
														</TableRow>
													);
												})
											) : (
												<TableRow key={0}>
													<Typography
														variant="body1"
														textAlign={"center"}
														gutterBottom
													>
														No menu items
													</Typography>
												</TableRow>
											)}
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
						)
					}

					{/*map over the menu.menu_items and display them in a MUI table */}
					{menus.length === 1 &&
						(menus[0].status === "Inactive" ? (
							<Typography variant="h4" textAlign={"center"} gutterBottom>
								Shop is closed
							</Typography>
						) : (
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell //make full width
											>
												Menu Items
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{menus[0]?.menu_items?.map((item) => {
											return (
												<TableRow key={item.menu_item_id}>
													<TableCell>
														<Card>
															<CardMedia
																component="img"
																height="140"
																image={item.image_url}
																alt="menu item"
															/>
															<CardContent>
																<Typography
																	gutterBottom
																	variant="h5"
																	component="div"
																>
																	{item.name}
																</Typography>
																<Typography
																	variant="body2"
																	color="text.secondary"
																>
																	{item.description}
																</Typography>
																<Typography
																	variant="body2"
																	color="text.secondary"
																>
																	{item.price}
																</Typography>
																<Button
																	variant="contained"
																	onClick={() => {
																		setAddToCartPopover(true);
																		setAddToCartItem(item);
																	}}
																>
																	Add to Cart
																</Button>
															</CardContent>
														</Card>
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
						))}
				</Box>
			)}
		</Box>
	);
}
