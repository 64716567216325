import Home from "./Components/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import NavBar from "./Components/NavBar/NavBar";
import { Box } from "@mui/system";
import Loading from "./Components/Loading/Loading";
import { useAuth0 } from "@auth0/auth0-react";
import TruckDetail from "./Components/TruckDetail/TruckDetail";
import PaymentScreen from "./Components/PaymentScreen/PaymentScreen";
import OrderConfirmation from "./Components/OrderConfirmation/OrderConfirmation";
import ManageTrucks from "./Components/ManageTrucks/ManageTrucks";
import Orders from "./Components/Orders/Orders";
import ViewQRCode from "./Components/ViewQRCode/ViewQRCode";
import AboutUs from "./Components/About/AboutUs";

function App() {
	const { isLoading } = useAuth0();

	const theme = createTheme({
		palette: {
			primary: {
				main: "#FFA500",
				backing: "#d3d3d3",
			},
			secondary: {
				main: "#000000",
			},
			background: {
				default: "#f5f5f5",
			},
		},
	});

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Box bgcolor={theme.palette.background.default}>
				<BrowserRouter>
					<NavBar />
					{isLoading ? (
						<Loading />
					) : (
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/aboutUs" element={<AboutUs />} />

							<Route path="/truck/:id" element={<TruckDetail />} />
							<Route
								path="/payNow/:clientSecret/:orderNumber"
								element={<PaymentScreen />}
							/>
							<Route
								path="/orderConfirmation/:orderNumber"
								element={<OrderConfirmation />}
							/>
							<Route path="/manageTrucks" element={<ManageTrucks />} />
							<Route path="/orders/:truck_id" element={<Orders />} />
							<Route path="/qrCode/:id" element={<ViewQRCode></ViewQRCode>} />
						</Routes>
					)}
				</BrowserRouter>
			</Box>
		</ThemeProvider>
	);
}

export default App;
