//This will be the component that comtains the form to edit a menu

import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { FormControl } from "@mui/base";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

export default function EditMenu(props) {
	const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
	const { setTruckInFocus, menuInFocus, setMenuInFocus } = props;
	const [editMenu, setEditMenu] = useState(menuInFocus);
	const [formStatus, setFormStatus] = useState("idle");
	const [usersDBMenuItems, setUsersDBMenuItems] = useState([]);

	useEffect(() => {
		setFormStatus("loading");
		axios
			.get(process.env.REACT_APP_BACKEND_URL + `/getUserMenuItems/${user?.sub}`)
			.then((res) => {
				setFormStatus("idle");
				setUsersDBMenuItems(res?.data);
			})

			.catch((error) => {
				setFormStatus("error");
				console.log(error);
			});
	}, [user]);

	const handleEditMenu = () => {
		setFormStatus("loading");

		const editMenuData = async () => {
			if (isAuthenticated && user) {
				const token = await getAccessTokenSilently();
				//pull out the menu_item_ids from the editMenu.menu_items array
				//this is to make the menu_items array match the format of the menu_items array in the database
				let menu_item_ids = editMenu?.menu_items?.map((menu_item) => {
					return menu_item.menu_item_id;
				});

				axios
					.post(
						process.env.REACT_APP_BACKEND_URL +
							`/editMenu/${menuInFocus?.menu_id}`,
						{ ...editMenu, active_menu_items: menu_item_ids },

						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then((res) => {
						setFormStatus("success");
						setTimeout(() => {
							setTruckInFocus(res?.data);
							setMenuInFocus(null);
							setFormStatus("idle");
						}, 1000);
					})

					.catch((error) => {
						setFormStatus("error");
						console.log(error);
					});
			}
		};
		editMenuData();
	};

	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			alignItems={"center"}
			justifyContent={"center"}
		>
			<Typography padding={1} variant="h2">
				Edit Menu
			</Typography>
			<Box padding={1}>
				<TextField
					label="Menu Name"
					value={editMenu?.name}
					onChange={(e) => {
						setEditMenu({ ...editMenu, name: e.target.value });
					}}
				></TextField>
			</Box>
			<Box padding={1}>
				<TextField
					label="Menu Description"
					value={editMenu?.description}
					onChange={(e) => {
						setEditMenu({ ...editMenu, description: e.target.value });
					}}
				></TextField>
			</Box>
			<Box padding={1}>
				<Select label="Menu Status" value={editMenu?.status}>
					<MenuItem
						value={"Active"}
						onClick={() => {
							setEditMenu({ ...editMenu, status: "Active" });
						}}
					>
						Active
					</MenuItem>
					<MenuItem
						value={"Inactive"}
						onClick={() => {
							setEditMenu({ ...editMenu, status: "Inactive" });
						}}
					>
						Inactive
					</MenuItem>
				</Select>
			</Box>

			<Box padding={1}>
				<Box
					display={"flex"}
					flexDirection={"row"}
					alignItems={"center"}
					justifyContent={"center"}
				>
					<Typography variant="h4" gutterBottom>
						Menu Items
					</Typography>
					<Box padding={1}>
						<Add
							onClick={() => {
								let newMenuItems = editMenu.menu_items;
								newMenuItems.push("");
								setEditMenu({ ...editMenu, menu_items: newMenuItems });
							}}
						/>
					</Box>
				</Box>

				{editMenu?.menu_items?.map((menu_item, index) => {
					return (
						<Box
							key={index}
							padding={1}
							display={"flex"}
							flexDirection={"row"}
							alignItems={"center"}
							justifyContent={"center"}
						>
							<TextField
								label={"Menu Item " + menu_item?.menu_item_id}
								value={menu_item?.name}
								disabled
								onChange={(e) => {
									let newMenuItems = editMenu.menu_items;
									newMenuItems[index] = e.target.value;
									setEditMenu({ ...editMenu, menu_items: newMenuItems });
								}}
							></TextField>

							<Remove
								onClick={() => {
									let newMenuItems = editMenu.menu_items;
									newMenuItems.splice(index, 1);
									setEditMenu({ ...editMenu, menu_items: newMenuItems });
								}}
							/>
						</Box>
					);
				})}
				<FormControl fullWidth>
					<InputLabel>Add Menu Item</InputLabel>
					<Select
						//make so it displays "Add menu item" when no menu item is selected

						onChange={(e) => {
							let newMenuItems = editMenu.menu_items;
							newMenuItems.push(e.target.value);
							setEditMenu({ ...editMenu, menu_items: newMenuItems });
						}}
					>
						{usersDBMenuItems.map((menuItem) => {
							//If the item is already in the menu, exclude it from the list
							let alreadyInMenu = false;
							editMenu?.menu_items?.forEach((menu_item) => {
								if (menu_item?.menu_item_id === menuItem?.menu_item_id) {
									alreadyInMenu = true;
								}
							});
							if (!alreadyInMenu) {
								return (
									<MenuItem key={menuItem?.menu_item_id} value={menuItem}>
										{menuItem.name}
									</MenuItem>
								);
							} else {
								//Tell the user that the item is already in the menu
								return (
									<MenuItem
										key={menuItem?.menu_item_id}
										value={menuItem}
										disabled
									>
										{menuItem.name} (already in menu)
									</MenuItem>
								);
							}
						})}
					</Select>
				</FormControl>
			</Box>
			{formStatus === "success" && (
				<Box padding={1}>
					<Alert severity="success">Menu Updated!</Alert>
				</Box>
			)}
			<Box padding={1}>
				<Button
					variant="contained"
					color="primary"
					disabled={formStatus === "loading"}
					onClick={handleEditMenu}
				>
					Save Menu
				</Button>
			</Box>
		</Box>
	);
}
