//This will be the truck in focus page when the truck owners is modifying their truck
//this page should have the ability to edit the truck details, add a menu, and edit the menu, add a menu item, and edit a menu item

import React, { useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import CustomPopover from "../CustomPopover/CustomPopover";
import EditTruckDetails from "./EditTruckDetails";
import ViewTruckMenus from "./ViewTruckMenus";
import TruckOrders from "./TruckOrders";
import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";
import axios from "axios";

export default function TruckInFocus(props) {
	const { truckInFocus, setTruckInFocus } = props;
	const [editTruckPopover, setEditTruckPopover] = useState(null);
	const [viewTruckMenuPopover, setViewTruckMenuPopover] = useState(null);
	const [formStatus, setFormStatus] = useState("idle");
	const navigate = useNavigate();

	const handleFinishStripeConnect = () => {
		setFormStatus("loadingStripeConnect");
		//make a call to the backend API to get a new stripe connect link
		axios
			.post(process.env.REACT_APP_BACKEND_URL + `/generateAccountLink`, {
				truck_id: truckInFocus?.truck_id,
			})
			.then((res) => {
				setFormStatus("stripeConnectLoaded");
				window.location.href = res.data.url;
				setTimeout(() => {
					setFormStatus("idle");
				}, 5000);
			});
	};

	// Render truck data
	return (
		<Box>
			{editTruckPopover ? (
				<CustomPopover setPopoverVisible={setEditTruckPopover}>
					<EditTruckDetails
						truckInFocus={truckInFocus}
						setTruckInFocus={setTruckInFocus}
						setEditTruckPopover={setEditTruckPopover}
					></EditTruckDetails>
				</CustomPopover>
			) : null}
			{viewTruckMenuPopover ? (
				<ViewTruckMenus
					truckInFocus={truckInFocus}
					setTruckInFocus={setTruckInFocus}
					setViewTruckMenuPopover={setViewTruckMenuPopover}
				></ViewTruckMenus>
			) : (
				<Box>
					<Box
						//position in the top left corner using MUI
						display={"flex"}
						justifyContent={"flex-start"}
						paddingTop={"10px"}
						paddingLeft={"10px"}
					>
						<ArrowBack
							//on hover white and pointer
							//
							sx={{ "&:hover": { color: "white", cursor: "pointer" } }}
							onClick={() => {
								setTruckInFocus(null);
							}}
						/>
					</Box>

					<Box
						display={"flex"}
						flexDirection={"column"}
						alignItems={"center"}
						justifyContent={"center"}
					>
						<Typography variant="h2">Manage Truck</Typography>
						<Box
							display={"flex"}
							flexDirection={"column"}
							alignItems={"center"}
							justifyContent={"center"}
							padding={2}
							backgroundColor={"grey"}
							borderRadius={5}
						>
							<Typography variant="h3">{truckInFocus?.name}</Typography>
							<Typography variant="body1">
								{truckInFocus?.description}
							</Typography>
						</Box>
					</Box>
					{truckInFocus?.stripe_status !== "Completed" ? (
						<Box
							padding={2}
							display={"flex"}
							flexDirection={"column"}
							alignItems={"center"}
							justifyContent={"center"}
						>
							<Box
								padding={2}
								display={"flex"}
								flexDirection={"column"}
								alignItems={"center"}
								justifyContent={"center"}
							>
								{formStatus === "loadingStripeConnect" ? (
									<Box width={"50px"}>
										<CircularProgress />
									</Box>
								) : formStatus === "stripeConnectLoaded" ? (
									<Box
										display={"flex"}
										flexDirection={"column"}
										alignItems={"center"}
										justifyContent={"center"}
									>
										<Box>
											<Typography
												variant={"body1"}
												style={{ textAlign: "center" }}
											>
												Redirecting to stripe connect...
											</Typography>
										</Box>

										<Box width={"50px"}>
											<CircularProgress />
										</Box>
									</Box>
								) : (
									<Button
										variant={"contained"}
										onClick={() => handleFinishStripeConnect()}
									>
										Finish Stripe Connect
									</Button>
								)}
							</Box>
							<Alert severity="info">
								You must finish connecting your Stripe account to accept
								payments. This can take ~5 minutes to sync if you just
								completed!
							</Alert>
						</Box>
					) : null}

					<Box
						display={"flex"}
						flexDirection={"column"}
						alignItems={"center"}
						justifyContent={"center"}
					>
						<Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
							<Box padding={1}>
								<Button
									variant={"contained"}
									onClick={() => setEditTruckPopover(true)}
								>
									Edit Truck
								</Button>
							</Box>
							<Box padding={1}>
								<Button
									variant={"contained"}
									onClick={() => setViewTruckMenuPopover(true)}
								>
									View/Edit Menus
								</Button>
							</Box>
						</Box>
						{truckInFocus?.stripe_status === "Completed" ? (
							<Box
								padding={1}
								display={"flex"}
								alignItems={"center"}
								justifyContent={"center"}
							>
								<Box padding={1}>
									<Button
										variant={"contained"}
										onClick={() =>
											navigate(`/orders/${truckInFocus?.truck_id}`)
										}
									>
										Enter Orders
									</Button>
								</Box>
								<Box padding={1}>
									<Button
										variant={"contained"}
										onClick={() =>
											navigate(`/qrCode/${truckInFocus?.truck_id}`)
										}
									>
										View QR Code
									</Button>
								</Box>
							</Box>
						) : null}
					</Box>
					{truckInFocus?.stripe_status === "Completed" ? (
						<TruckOrders
							truckInFocus={truckInFocus}
							setTruckInFocus={setTruckInFocus}
						></TruckOrders>
					) : null}
				</Box>
			)}
		</Box>
	);
}
