import React, { useEffect, useState } from "react";
import {
	PaymentElement,
	useStripe,
	useElements,
} from "@stripe/react-stripe-js";
import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import { FormControl } from "@mui/base";
import { Alert } from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";

export default function CheckoutForm(props) {
	const stripe = useStripe();
	const elements = useElements();
	const { clientSecret, orderNumber } = props;
	const returnURL =
		//update to the order confirmation page
		//TODO add order number to the URL
		window.location.origin + "/orderConfirmation/" + orderNumber;

	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!stripe) {
			return;
		}

		if (!clientSecret) {
			return;
		}

		stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
			switch (paymentIntent.status) {
				case "succeeded":
					setMessage("Payment succeeded!");
					break;
				case "processing":
					setMessage("Your payment is processing.");
					break;
				case "requires_payment_method":
					setMessage("Please enter your details to complete the payment.");
					break;
				default:
					setMessage("Something went wrong.");
					break;
			}
		});
	}, [stripe, clientSecret]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js hasn't yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setIsLoading(true);

		const response = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: returnURL,
			},
		});

		const { error } = response;

		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		if (error.type === "card_error" || error.type === "validation_error") {
			setMessage(error.message);
		} else {
			setMessage("An unexpected error occurred.");
		}

		setIsLoading(false);
	};

	const paymentElementOptions = {
		layout: "tabs",
	};

	return (
		<FormControl onSubmit={handleSubmit}>
			<Box
				display={"flex"}
				flexDirection={"column"}
				alignItems={"center"}
				justifyContent={"center"}
			>
				{message ? (
					<Box>
						<PaymentElement
							id="payment-element"
							options={paymentElementOptions}
						/>
						<Box padding={2}>
							<Alert severity="info">{message}</Alert>
						</Box>
					</Box>
				) : (
					//loading
					<Box
						display={"flex"}
						flexDirection={"column"}
						alignItems={"center"}
						justifyContent={"center"}
					>
						<CircularProgress />
					</Box>
				)}
				<Box padding={2}>
					{!isLoading ? (
						<Button
							variant="contained"
							disabled={isLoading || !stripe || !elements}
							id="submit"
							onClick={handleSubmit}
						>
							Pay now
						</Button>
					) : (
						<Typography variant="body1">Submitting...</Typography>
					)}
				</Box>
			</Box>
		</FormControl>
	);
}
