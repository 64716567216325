//This will be the page to add a new truck. This page should have a form to add a new truck, and a button to submit the form
import React, { useState } from "react";
import axios from "axios";
import { Box, TextField, Button, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import { Alert } from "@mui/material";

export default function AddTruck(props) {
	const { setAddTruckPopover, setTrucksData } = props;
	const [formStatus, setFormStatus] = useState("idle");
	const [newTruck, setNewTruck] = useState({
		name: "",
		description: "",
		food_type: "",
		phone_number: "",
		home_zip: "",
		truck_notes: "",
	});
	const { user } = useAuth0();

	const handleTruckChange = (e) => {
		setNewTruck({ ...newTruck, [e.target.name]: e.target.value });
	};

	const addTruck = () => {
		setFormStatus("creatingTruck");
		axios
			.post(process.env.REACT_APP_BACKEND_URL + `/createTruck`, {
				...newTruck,
				user_sub: user.sub,
			})
			.then((res) => {
				setFormStatus("success");
				window.location.href = res.data.accountLink.url;
				setTimeout(() => {
					setFormStatus("idle");
					setTrucksData((prev) => [...prev, res?.data]);
					setAddTruckPopover(false);
					//navigate to the stripe onboarding page
					//naviate to the external url

					//navigate(res.data.accountLink.url);
				}, 1000);
			})
			.catch((error) => {
				setFormStatus("error");
				console.log(error);
			});
	};
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Typography variant="h4" gutterBottom>
				Add Truck
			</Typography>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<TextField
					label="Truck Name"
					name="name"
					onChange={handleTruckChange}
					value={newTruck.name}
					margin="normal"
					variant="outlined"
				/>
				<TextField
					label="Truck Description"
					name="description"
					onChange={handleTruckChange}
					value={newTruck.description}
					margin="normal"
					variant="outlined"
				/>
				<TextField
					label="Food Type"
					name="food_type"
					onChange={handleTruckChange}
					value={newTruck.food_type}
					margin="normal"
					variant="outlined"
				/>
				<TextField
					label="Phone Number"
					name="phone_number"
					onChange={handleTruckChange}
					value={newTruck.phone_number}
					margin="normal"
					variant="outlined"
				/>
				<TextField
					label="Home Zip Code"
					name="home_zip"
					onChange={handleTruckChange}
					value={newTruck.home_zip}
					margin="normal"
					variant="outlined"
				/>
				<TextField
					label="Notes"
					name="truck_notes"
					multiline
					rows={2}
					onChange={handleTruckChange}
					value={newTruck.truck_notes}
					margin="normal"
					variant="outlined"
				/>
				<Box
					display={"flex"}
					flexDirection={"row"}
					alignItems={"center"}
					justifyContent={"center"}
				>
					<Box padding={1}>
						<Button
							variant="contained"
							color="primary"
							disabled={formStatus === "creatingTruck"}
							startIcon={<Add />}
							onClick={addTruck}
						>
							{formStatus === "creatingTruck" ? "Adding Truck..." : "Add Truck"}
						</Button>
					</Box>
					<Box padding={1}>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => setAddTruckPopover(false)}
						>
							Cancel
						</Button>
					</Box>
				</Box>
			</Box>
			{formStatus === "success" && (
				<Alert severity="success">Truck added! Navigating to Stripe...</Alert>
			)}
			{formStatus === "error" && (
				<Alert severity="error">Error adding truck</Alert>
			)}
		</Box>
	);
}
