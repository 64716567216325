//This will be a boilerplate home screen welcoming the user to No Line Feast
//And allowing them to input a food truck number to get started

import React, { useEffect } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import logo from "../Assets/NoLineLogo.jpeg";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";

export default function Home(props) {
	const [truckNumber, setTruckNumber] = React.useState(0);
	const [truckData, setTruckData] = React.useState([{}]);
	const [formStatus, setFormStatus] = React.useState("idle");
	const navigate = useNavigate();

	const searchForTruck = () => {
		//set form status to loading
		setFormStatus("loading");
		//search for truck in database

		axios
			.get(process.env.REACT_APP_BACKEND_URL + `/trucks/${truckNumber}`)
			.then((res) => {
				if (res?.data === null) {
					setFormStatus("No Truck Found");
					setTimeout(() => {
						setFormStatus("idle");
					}, 3000);
				} else {
					setTruckData(res?.data);
					setFormStatus("idle");
				}
			});

		//setFormStatus("idle");
	};

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: `/`,
			title: "Home",
		});
	}, []);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				height: "100vh",
			}}
		>
			<Helmet>
				<title>No Line Feast</title>
				<meta
					name="description"
					content="The easiest way to order from your favorite food trucks"
				/>

				<meta
					name="keywords"
					content="food, truck, order, line, feast, no line, no line feast"
				/>

				<meta name="author" content="No Line Feast" />

				<meta name="viewport" content="width=device-width, initial-scale=1.0" />

				<meta property="og:title" content="No Line Feast" />

				<meta
					property="og:description"
					content="The easiest way to order from your favorite food trucks"
				/>

				<meta
					property="og:image"
					content="https://nolinefeast.com/static/media/NoLineLogo.6f5e5f6e.jpeg"
				/>
			</Helmet>

			{truckData?.truck_id ? (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Typography
						variant="h2"
						gutterBottom
						//center the text
						style={{ textAlign: "center" }}
					>
						{truckData?.name || "No Truck Found"}
					</Typography>
					<Typography variant="h5" gutterBottom style={{ textAlign: "center" }}>
						{truckData?.food_type || "No description found"}
					</Typography>
					<Button
						onClick={() => navigate(`/truck/${truckData?.truck_id}`)}
						variant="contained"
						sx={{ margin: "1rem" }}
					>
						Order From This Truck
					</Button>
				</Box>
			) : (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						height: "100vh",
					}}
				>
					<Box padding={2}>
						<Box
							padding={1}
							backgroundColor="rgba(0, 0, 0, 0.1)"
							borderRadius={2}
						>
							{
								//use the MUI package to add the logo
							}
							<Box
								display={"flex"}
								flexDirection={"column"}
								alignItems={"center"}
								justifyContent={"center"}
							>
								<Box
									padding={2}
									display={"flex"}
									flexDirection={"column"}
									alignItems={"center"}
									justifyContent={"center"}
								>
									<img
										src={logo}
										alt="No Line Feast Logo"
										style={{ width: "50%" }}
									/>
								</Box>
								<Box padding={2}>
									<Typography
										variant="h5"
										gutterBottom
										style={{ textAlign: "center" }}
									>
										Welcome to No Line Feast! The easiest way to order from your
										favorite food trucks
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>

					<Typography variant="h6" gutterBottom style={{ textAlign: "center" }}>
						Please enter your food truck number to get started
					</Typography>
					<TextField
						id="outlined-basic"
						label="Food Truck Number"
						variant="outlined"
						value={truckNumber}
						onChange={(e) => setTruckNumber(e.target.value)}
					/>

					<Box padding={2}>
						{formStatus === "No Truck Found" ? (
							<Typography
								variant="h6"
								gutterBottom
								style={{ textAlign: "center", color: "red" }}
							>
								No Truck Found
							</Typography>
						) : null}
						{formStatus === "loading" ? (
							<CircularProgress />
						) : (
							<Button
								onClick={searchForTruck}
								disabled={truckNumber === 0}
								variant="contained"
								sx={{ margin: "1rem" }}
							>
								Get Started
							</Button>
						)}
					</Box>
				</Box>
			)}
		</Box>
	);
}
