//This page will be where a user comes to edit their menu items. They will be able to add, remove, and edit menu items.
//This page will also have a button to submit the changes to the menu items
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Button, Typography } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import CustomPopover from "../../CustomPopover/CustomPopover";
import AddMenuItem from "./AddMenuItem";
import { Alert } from "@mui/material";

import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from "@mui/material";

import ArrowBack from "@mui/icons-material/ArrowBack";
import EditMenuItem from "./EditMenuItem";
import Loading from "../../Loading/Loading";

export default function EditMenuItems(props) {
	const { user } = useAuth0();
	const { setEditMenuItemsPopover } = props;
	const [formStatus, setFormStatus] = useState("idle");
	const [usersDBMenuItems, setUsersDBMenuItems] = useState([]);
	const [confirmDeletePopover, setConfirmDeletePopover] = useState(false);
	const [menuItemInFocus, setMenuItemInFocus] = useState({});
	const [editSingleMenuItem, setEditSingleMenuItem] = useState(false);
	const [addMenuItemPopover, setAddMenuItemPopover] = useState(false);

	useEffect(() => {
		if (!addMenuItemPopover && !editSingleMenuItem && !confirmDeletePopover) {
			setFormStatus("loading");
			axios
				.get(
					process.env.REACT_APP_BACKEND_URL + `/getUserMenuItems/${user?.sub}`
				)
				.then((res) => {
					setFormStatus("idle");
					setUsersDBMenuItems(res?.data);
				})
				.catch((error) => {
					setFormStatus("error");
					console.log(error);
				});
		}
	}, [user, addMenuItemPopover, editSingleMenuItem, confirmDeletePopover]);

	const handleRemoveMenuItem = (menuItem) => {
		setFormStatus("loading");
		axios
			.delete(
				process.env.REACT_APP_BACKEND_URL +
					`/deleteMenuItem/${menuItem.menu_item_id}`
			)
			.then((res) => {
				setFormStatus("idle");
				setUsersDBMenuItems(
					usersDBMenuItems.filter(
						(item) => item.menu_item_id !== menuItem.menu_item_id
					)
				);
			})
			.catch((error) => {
				setFormStatus("error");
				console.log(error);
			});
	};

	return (
		<Box padding={2}>
			{addMenuItemPopover && (
				<CustomPopover setPopoverVisible={setAddMenuItemPopover}>
					<AddMenuItem setAddMenuItemPopover={setAddMenuItemPopover} />
				</CustomPopover>
			)}
			{confirmDeletePopover && (
				<CustomPopover setPopoverVisible={setConfirmDeletePopover}>
					<Box padding={2}>
						<Typography variant="h6" component="h6" gutterBottom>
							Are you sure you want to delete this menu item?
						</Typography>
						<Box padding={2}>
							<Button
								onClick={() => {
									handleRemoveMenuItem(menuItemInFocus);
									setConfirmDeletePopover(false);
								}}
								variant="contained"
								color="error"
							>
								Delete
							</Button>
							<Button
								onClick={() => {
									setConfirmDeletePopover(false);
								}}
								variant="contained"
								color="primary"
							>
								Cancel
							</Button>
						</Box>
					</Box>
				</CustomPopover>
			)}
			{editSingleMenuItem && (
				<CustomPopover setPopoverVisible={setEditSingleMenuItem}>
					<EditMenuItem
						setEditSingleMenuItem={setEditSingleMenuItem}
						menuItemInFocus={menuItemInFocus}
						setMenuItemInFocus={setMenuItemInFocus}
					/>
				</CustomPopover>
			)}
			{formStatus !== "loading" ? (
				<Box>
					<Box
						//position in the top left corner using MUI
						display={"flex"}
						justifyContent={"flex-start"}
						paddingTop={"10px"}
						paddingLeft={"10px"}
					>
						<ArrowBack
							sx={{ "&:hover": { color: "white", cursor: "pointer" } }}
							onClick={() => {
								setEditMenuItemsPopover(false);
							}}
						/>
					</Box>
					<Box>
						<Box
							display={"flex"}
							alignItems={"center"}
							flexDirection={"row"} //center the content
						>
							<Typography variant="h6" component="h6" gutterBottom>
								Menu Items
							</Typography>
							<Box padding={2}>
								<Button
									variant="contained"
									color="primary"
									onClick={() => {
										setAddMenuItemPopover(!addMenuItemPopover);
									}}
								>
									<Add />
								</Button>
							</Box>
						</Box>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Item</TableCell>
									<TableCell>Price</TableCell>
									<TableCell>Remove</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{usersDBMenuItems?.map((menuItem, index) => {
									return (
										<TableRow key={index}>
											<TableCell
												onClick={() => {
													setMenuItemInFocus(menuItem);
													setEditSingleMenuItem(true);
												}}
											>
												{menuItem.name}
											</TableCell>
											<TableCell
												onClick={() => {
													setMenuItemInFocus(menuItem);
													setEditSingleMenuItem(true);
												}}
											>
												{menuItem.price}
											</TableCell>
											<TableCell>
												<Button
													onClick={() => {
														setMenuItemInFocus(menuItem);
														setConfirmDeletePopover(true);
													}}
													variant="contained"
													color="error"
												>
													<Remove />
												</Button>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>

						<Box>
							{formStatus === "success" && (
								<Alert severity="success">Menu Items Updated</Alert>
							)}
							{formStatus === "error" && <Alert severity="error">Error</Alert>}
						</Box>
					</Box>
				</Box>
			) : (
				<Loading />
			)}
		</Box>
	);
}
