import React, { useEffect, useRef } from "react";
import QRCode from "qrcode.react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import { Typography } from "@material-ui/core";
import { Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import ReactGA from "react-ga4";

const ViewQRCode = () => {
	// Get the truck id from the URL using useParams
	const { id } = useParams();
	const qrCodeRef = useRef(null);
	const navigate = useNavigate();

	const downloadQRCode = () => {
		// generate canvas
		const canvas = qrCodeRef.current.querySelector("canvas");
		// create png from the canvas
		const pngUrl = canvas
			.toDataURL("image/png")
			.replace("image/png", "image/octet-stream");
		let downloadLink = document.createElement("a");
		downloadLink.href = pngUrl;
		downloadLink.download = "QRCode.png";
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: `/qrCode/${id}`,
			title: "qrCode",
		});
	}, [id]);

	return (
		<Box>
			<Helmet>
				<title>QR Code</title>
				<meta name="description" content="QR Code" />
				<meta
					name="keywords"
					content="food, truck, order, line, feast, no line, no line feast"
				/>
			</Helmet>
			<Box
				//position in the top left corner using MUI
				display={"flex"}
				justifyContent={"flex-start"}
				paddingTop={"10px"}
				paddingLeft={"10px"}
			>
				<ArrowBack
					//on hover white and pointer
					//
					sx={{ "&:hover": { color: "white", cursor: "pointer" } }}
					onClick={() => {
						navigate(-1);
					}}
				/>
			</Box>
			<Box
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				padding={5}
			>
				<Typography variant="h4" gutterBottom>
					Truck {id}
				</Typography>

				<Box ref={qrCodeRef}>
					<QRCode value={`www.nolinefeast.com/truck/${id}`} />
				</Box>
				<Box padding={2}>
					<Button variant={"contained"} onClick={downloadQRCode}>
						Download QR Code
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default ViewQRCode;
