import React, { useState } from "react";
import {
	Box,
	Button,
	Typography,
	TextField,
	Checkbox,
	Alert,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export default function ModifyItemAddToCart({
	menu_item,
	setCart,
	setPopoverVisible,
}) {
	const [selectedIngredients, setSelectedIngredients] = useState(
		menu_item?.ingredients
	);
	const [notes, setNotes] = useState("");
	const [formStatus, setFormStatus] = useState("idle");

	const handleAddToCart = () => {
		const item = {
			...menu_item,
			selectedIngredients,
			notes,
		};
		setCart((prev) => [...prev, item]);
		setFormStatus("success");
		setTimeout(() => {
			setFormStatus("idle");
			setPopoverVisible(false);
		}, 1000);
	};
	//A postgresQL command to add a new column to the menu_items table
	//ALTER TABLE menu_items ADD COLUMN ingredients jsonb;
	//Add some mock data to the ingredients column
	//UPDATE menu_items SET ingredients = '["lettuce", "tomato", "onion"]' WHERE id = 1;

	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			alignItems={"center"}
			justifyContent={"center"}
		>
			<Typography variant="h4" gutterBottom style={{ textAlign: "center" }}>
				{menu_item.name}
			</Typography>
			<Box padding={1}>
				<Typography variant="h5" gutterBottom style={{ textAlign: "center" }}>
					Ingredients
				</Typography>
				{menu_item?.ingredients?.map((ingredient) => {
					return (
						<Box
							key={ingredient}
							padding={1}
							display={"flex"}
							flexDirection={"row"}
							alignItems={"center"}
							justifyContent={"center"}
						>
							<Box>
								<Typography>{ingredient}</Typography>
							</Box>
							<Box>
								{
									//checkbox input
								}
								<Checkbox
									checked={selectedIngredients.includes(ingredient)}
									onChange={(event) => {
										if (event.target.checked) {
											setSelectedIngredients((prev) => [...prev, ingredient]);
										} else {
											setSelectedIngredients((prev) =>
												prev.filter((item) => item !== ingredient)
											);
										}
									}}
								/>
							</Box>
						</Box>
					);
				})}
			</Box>

			<Box padding={1}>
				<TextField
					id="outlined-multiline-static"
					label="Notes, Allergies, etc."
					multiline
					rows={2}
					defaultValue=""
					variant="outlined"
					onChange={(event) => {
						setNotes(event.target.value);
					}}
				/>
			</Box>
			{formStatus === "success" ? (
				<Box padding={1} borderRadius={2} backgroundColor={"#4caf50"}>
					<Alert
						iconMapping={{
							success: <CheckCircleOutlineIcon fontSize="inherit" />,
						}}
					>
						Item Added to Cart!
					</Alert>
				</Box>
			) : null}

			<Button variant="contained" onClick={handleAddToCart}>
				Add to Cart
			</Button>
		</Box>
	);
}
