//This will take be 2 buttons, a confirm and a cancel. The cancel button will close the popover and the confirm button will delete the menu

import React from "react";
import { Box, Button, Typography } from "@mui/material";
import axios from "axios";

export default function DeleteMenu(props) {
	const { menuInFocus, setMenuInFocus, setTruckInFocus } = props;

	const deleteMenu = () => {
		axios
			.delete(
				process.env.REACT_APP_BACKEND_URL +
					`/deleteMenu/${menuInFocus?.menu_id}`
			)
			.then((res) => {
				setTruckInFocus(res?.data);
				setMenuInFocus(null);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			alignItems={"center"}
			justifyContent={"center"}
		>
			<Box padding={1}>
				<Typography variant="body1">
					Are you sure you want to delete this menu?
				</Typography>
			</Box>
			<Box
				display={"flex"}
				flexDirection={"row"}
				alignItems={"center"}
				justifyContent={"center"}
			>
				<Box padding={1}>
					<Button
						variant="contained"
						color="error"
						onClick={() => {
							deleteMenu();
						}}
					>
						Delete
					</Button>
				</Box>
				<Box padding={1}>
					<Button
						variant="contained"
						color="warning"
						onClick={() => {
							setMenuInFocus(null);
						}}
					>
						Cancel
					</Button>
				</Box>
			</Box>
		</Box>
	);
}
