//This will be the component that will allow the user to edit a single menu item
//
import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import {
	Alert,
	Box,
	Button,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	Table,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";

export default function EditMenuItem(props) {
	const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
	const { setEditSingleMenuItem, menuItemInFocus, setMenuItemInFocus } = props;
	const [editMenuItem, setEditMenuItem] = useState(menuItemInFocus);
	const [formStatus, setFormStatus] = useState("idle");

	const handleEditMenuItem = () => {
		setFormStatus("loading");

		const editMenuItemData = async () => {
			if (isAuthenticated && user) {
				const token = await getAccessTokenSilently();
				axios
					.post(
						process.env.REACT_APP_BACKEND_URL +
							`/editMenuItem/${menuItemInFocus?.menu_item_id}`,
						editMenuItem,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then((res) => {
						setFormStatus("success");
						setTimeout(() => {
							setMenuItemInFocus(res?.data);
							setEditSingleMenuItem(false);
							setFormStatus("idle");
						}, 1000);
					})

					.catch((error) => {
						setFormStatus("error");
						console.log(error);
					});
			}
		};
		editMenuItemData();
	};

	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			alignItems={"center"}
			justifyContent={"center"}
		>
			<Typography padding={1} variant="h2">
				Edit Menu Item
			</Typography>
			<Box padding={1}>
				<img src={editMenuItem?.image_url} alt="menu item" />
			</Box>
			<Box padding={1}>
				<TextField
					label="Name"
					variant="outlined"
					value={editMenuItem?.name}
					onChange={(e) =>
						setEditMenuItem({ ...editMenuItem, name: e.target.value })
					}
				/>
			</Box>
			<Box padding={1}>
				<TextField
					label="Description"
					variant="outlined"
					value={editMenuItem?.description}
					onChange={(e) =>
						setEditMenuItem({
							...editMenuItem,
							description: e.target.value,
						})
					}
				/>
			</Box>
			<Box padding={1}>
				<TextField
					label="Price"
					variant="outlined"
					value={editMenuItem?.price}
					onChange={(e) =>
						setEditMenuItem({ ...editMenuItem, price: e.target.value })
					}
				/>
			</Box>
			<Box padding={1}>
				<TableContainer>
					{
						//display the ingredients in a table, with a button at the bottom to add an ingredient and a button next to each ingredient removing the ingreient
					}
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Ingredient</TableCell>
								<TableCell>Remove</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{editMenuItem?.ingredients?.map((ingredient, index) => {
								return (
									<TableRow key={index}>
										<TableCell>
											<TextField
												label="Ingredient"
												variant="outlined"
												value={ingredient}
												onChange={(e) => {
													let newIngredients = editMenuItem.ingredients;
													newIngredients[index] = e.target.value;
													setEditMenuItem({
														...editMenuItem,
														ingredients: newIngredients,
													});
												}}
											/>
										</TableCell>
										<TableCell>
											<Button
												variant="contained"
												color="secondary"
												onClick={() => {
													let newIngredients = editMenuItem.ingredients;
													newIngredients.splice(index, 1);
													setEditMenuItem({
														...editMenuItem,
														ingredients: newIngredients,
													});
												}}
											>
												<Remove />
											</Button>
										</TableCell>
									</TableRow>
								);
							})}
							<TableRow>
								<TableCell>
									<Typography>Add Ingredient</Typography>
								</TableCell>
								<TableCell>
									<Button
										variant="contained"
										color="primary"
										onClick={() => {
											setEditMenuItem({
												...editMenuItem,
												ingredients: [...editMenuItem.ingredients, ""],
											});
										}}
									>
										<Add />
									</Button>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
			<Box padding={1}>
				<Button
					variant="contained"
					color="primary"
					onClick={handleEditMenuItem}
				>
					Save
				</Button>
			</Box>
			{formStatus === "success" && (
				<Alert severity="success">Menu Item Updated</Alert>
			)}
			{formStatus === "error" && (
				<Alert severity="error">Error Updating Menu Item</Alert>
			)}
		</Box>
	);
}
