//This will be the component that will show the details of a specific order
//
import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableContainer,
	TableBody,
} from "@mui/material";

export default function ViewOrderDetails(props) {
	const { orderInFocus } = props;
	const [orderDetails, setOrderDetails] = useState([]);

	useEffect(() => {
		setOrderDetails(orderInFocus);
	}, [orderInFocus]);

	return (
		<Box>
			<Box
				display={"flex"}
				flexDirection={"column"}
				justifyContent={"center"}
				alignItems={"center"}
			>
				<Typography variant={"h3"}>Order Details</Typography>
				<Box>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Item Name</TableCell>
									<TableCell>Quantity</TableCell>
									<TableCell>Price</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{orderDetails &&
									orderDetails?.items?.map((item, index) => {
										const parsedItem = JSON.parse(item);
										return (
											<TableRow key={index}>
												<TableCell>{parsedItem.name}</TableCell>
												<TableCell>{parsedItem.description}</TableCell>
												<TableCell>{parsedItem.price}</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Box>
		</Box>
	);
}
