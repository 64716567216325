//A functional component that will be used for all loading scenarios
//The component should be a styled MUI component with a spinner in the middle
//The component should be imported into all components that have loading scenarios
//

import React from "react";
import "./Loading.css";
import { CircularProgress, Box } from "@mui/material";

export default function Loading() {
	return (
		<Box height={"100vh"} width={"100vw"}>
			<Box className="loading">
				<CircularProgress />
			</Box>
		</Box>
	);
}
