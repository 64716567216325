//This will be the page to create a whole new menu.
//There should be a popup to add a new menu item
//There should be a textfield to add all the required fiellds for a new menu

import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import {
	Alert,
	Box,
	Button,
	TableContainer,
	TextField,
	Typography,
	Grid,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";

import { FormControl } from "@mui/base";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import CustomPopover from "../../CustomPopover/CustomPopover";
import AddMenuItem from "./AddMenuItem";
import { ArrowBack } from "@mui/icons-material";
import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from "@mui/material";

export default function AddMenu(props) {
	const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
	const { truckInFocus, setAddMenuPopover } = props;
	const [newMenu, setNewMenu] = useState({
		name: "",
		description: "",
		truck_id: truckInFocus?.truck_id,
		active_menu_items: [],
		status: "Active",
	});
	const [formStatus, setFormStatus] = useState("idle");
	const [addMenuItemPopover, setAddMenuItemPopover] = useState(false);
	const [usersDBMenuItems, setUsersDBMenuItems] = useState([]);

	useEffect(() => {
		setFormStatus("loading");
		axios
			.get(process.env.REACT_APP_BACKEND_URL + `/getUserMenuItems/${user?.sub}`)
			.then((res) => {
				setFormStatus("idle");
				setUsersDBMenuItems(res?.data);
			})

			.catch((error) => {
				setFormStatus("error");
				console.log(error);
			});
	}, [user, addMenuItemPopover]);

	const handleAddMenu = () => {
		setFormStatus("creatingMenu");

		const addMenuData = async () => {
			if (isAuthenticated && user) {
				const token = await getAccessTokenSilently();

				//loop through the active_menu_items and only send the menu_item_id
				let menuItems = [];
				newMenu.active_menu_items.forEach((item) => {
					menuItems.push(item.menu_item_id);
				});

				axios
					.post(
						process.env.REACT_APP_BACKEND_URL + `/createMenu`,
						{ ...newMenu, active_menu_items: menuItems },
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then(() => {
						setFormStatus("menuCreated");
						setTimeout(() => {
							setAddMenuPopover(false);
							setFormStatus("idle");
						}, 1000);
					})
					.catch((error) => {
						setFormStatus("error");
						console.log(error);
					});
			}
		};

		addMenuData();
	};

	return (
		<Box padding={2}>
			{addMenuItemPopover ? (
				<CustomPopover setPopoverVisible={setAddMenuItemPopover}>
					<AddMenuItem
						newMenu={newMenu}
						setNewMenu={setNewMenu}
						setAddMenuItemPopover={setAddMenuItemPopover}
					/>
				</CustomPopover>
			) : null}
			<Box
				//position in the top left corner using MUI
				display={"flex"}
				justifyContent={"flex-start"}
				paddingTop={"10px"}
				paddingLeft={"10px"}
			>
				<ArrowBack
					sx={{
						"&:hover": {
							cursor: "pointer",
							color: "white",
						},
					}}
					onClick={() => {
						setAddMenuPopover(false);
					}}
				/>
			</Box>
			<Box
				display={"flex"}
				flexDirection={"column"}
				justifyContent={"center"}
				alignItems={"center"}
			>
				<Box textAlign={"center"}>
					<Box mb={"2.5vh"}>
						<Typography variant="h3">Add Menu</Typography>
					</Box>
				</Box>
				<Box
					display={"flex"}
					flexDirection={"column"}
					justifyContent={"center"}
					alignItems={"center"}
				>
					<Box>
						<FormControl fullWidth>
							<TextField
								label="Menu Name"
								variant="outlined"
								value={newMenu.name}
								onChange={(e) =>
									setNewMenu({ ...newMenu, name: e.target.value })
								}
							/>
						</FormControl>
					</Box>
					<Box mt={"2.5vh"}>
						<FormControl fullWidth>
							<TextField
								label="Menu Description"
								variant="outlined"
								value={newMenu.description}
								onChange={(e) =>
									setNewMenu({ ...newMenu, description: e.target.value })
								}
							/>
						</FormControl>
					</Box>
					<Box mt={"2.5vh"}>
						<FormControl fullWidth>
							<InputLabel>Status</InputLabel>
							<Select
								id="status"
								value={newMenu.status}
								defaultValue="Active"
								label="Status"
								onChange={(e) =>
									setNewMenu({ ...newMenu, status: e.target.value })
								}
							>
								<MenuItem value={"Active"}>Active</MenuItem>
								<MenuItem value={"Inactive"}>Inactive</MenuItem>
								<MenuItem value={"Under Construction"}>
									Under Construction
								</MenuItem>

								<MenuItem value={"Scheduled"}>Scheduled</MenuItem>
							</Select>
						</FormControl>
					</Box>
					<Box
						mt={"2.5vh"}
						display={"flex"}
						flexDirection={"column"}
						justifyContent={"center"}
						alignItems={"center"}
					>
						<Typography variant="h4" gutterBottom>
							Menu Items
						</Typography>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Item Name</TableCell>
										<TableCell>Description</TableCell>
										<TableCell align="center">Price</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{
										//map over the menu items and display them in the table
										newMenu?.active_menu_items &&
											newMenu.active_menu_items.map((menu_item, index) => {
												return (
													<TableRow key={index}>
														<TableCell>{menu_item.name}</TableCell>
														<TableCell>{menu_item.description}</TableCell>
														<TableCell>
															<Grid container>
																<Grid item>$ {menu_item.price}</Grid>
																<Grid item>
																	<Remove
																		onClick={() => {
																			let newMenuItems =
																				newMenu.active_menu_items;
																			newMenuItems.splice(index, 1);
																			setNewMenu({
																				...newMenu,
																				active_menu_items: newMenuItems,
																			});
																		}}
																	/>
																</Grid>
															</Grid>
														</TableCell>
													</TableRow>
												);
											})
									}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
					<Box mt={"2.5vh"}>
						<FormControl fullWidth>
							<InputLabel>Add Menu Item</InputLabel>
							<Select
								//make so it displays "Add menu item" when no menu item is selected
								sx={{ width: "100%" }}
								onChange={(e) => {
									let newMenuItems = newMenu.active_menu_items;
									newMenuItems.push(e.target.value);
									setNewMenu({ ...newMenu, active_menu_items: newMenuItems });
								}}
							>
								{
									//if all of the menu items have been added, display a message
									usersDBMenuItems.length ===
									newMenu?.active_menu_items?.length ? (
										<MenuItem disabled value={"All menu items added"}>
											All menu items added
										</MenuItem>
									) : null
								}
								{usersDBMenuItems.map((menuItem) => {
									//If the item is already in the menu, exclude it from the list
									let alreadyInMenu = false;
									newMenu?.active_menu_items?.forEach((menu_item) => {
										if (menu_item.menu_item_id === menuItem.menu_item_id) {
											alreadyInMenu = true;
										}
									});
									if (!alreadyInMenu) {
										return (
											<MenuItem key={menuItem.menu_item_id} value={menuItem}>
												{menuItem.name}
											</MenuItem>
										);
									} else {
										//Tell the user that the item is already in the menu
										return null;
									}
								})}
							</Select>
						</FormControl>
					</Box>

					<Box
						mt={"2.5vh"}
						display={"flex"}
						flexDirection={"column"}
						alignItems={"center"}
						justifyContent={"center"}
					>
						<Button
							variant="contained"
							color="primary"
							onClick={() => setAddMenuItemPopover(true)}
						>
							Create a Menu Item
						</Button>
					</Box>
					<Box
						mt={"2.5vh"}
						display={"flex"}
						flexDirection={"column"}
						alignItems={"center"}
						justifyContent={"center"}
					>
						{
							//if formStatus is "creatingMenu", display a loading spinner in the button
							formStatus === "creatingMenu" ? (
								<Button
									variant="contained"
									color="primary"
									disabled
									startIcon={<Add />}
								>
									Adding Menu...
								</Button>
							) : (
								<Button
									variant="contained"
									color="primary"
									onClick={() => handleAddMenu()}
								>
									Save Menu
								</Button>
							)
						}
					</Box>
				</Box>

				{formStatus === "menuCreated" ? (
					<Alert severity="success" style={{ marginTop: "2.5vh" }}>
						Menu Added!
					</Alert>
				) : null}
			</Box>
		</Box>
	);
}
