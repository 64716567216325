//Display a table with the menu items that are in the cart

import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
	Box,
	Button,
	CircularProgress,
	TableContainer,
	TextField,
	Typography,
} from "@mui/material";

import { Close } from "@mui/icons-material";
import {
	Table,
	TableRow,
	TableCell,
	TableBody,
	TableHead,
} from "@mui/material";
import { Alert } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
//impoort react currency formatter
import CurrencyFormat from "react-currency-format";

export default function Cart(props) {
	const { cart, setCart, truck_id } = props;
	const navigate = useNavigate();
	const [formStatus, setFormStatus] = React.useState("idle");
	const [errorMessage, setErrorMessage] = React.useState("");
	const [email, setEmail] = React.useState("");

	const calculateBalance = (cart) => {
		let balance = 0;
		cart.forEach((item) => {
			balance += parseFloat(item.price);
		});
		return balance;
	};

	const handleCheckout = () => {
		setFormStatus("loading");
		//if the email is empty, display an error message
		if (
			!email ||
			email === "" ||
			email === " " ||
			email === "  " ||
			email === "N/A" ||
			email === "n/a"
		) {
			setFormStatus("error");
			setErrorMessage("Please enter an email address.");
			return;
		}

		//reset the error message
		setErrorMessage("");
		setFormStatus("loading");
		axios
			.post(process.env.REACT_APP_BACKEND_URL + "/createOrder", {
				cart: cart,
				truck_id,
				email,
			})
			.then((res) => {
				setFormStatus("success");
				navigate(`/payNow/${res?.data?.clientSecret}/${res?.data?.order_id}`);
			})
			.catch((err) => {
				setFormStatus("error");
				setErrorMessage(
					"An error occured when trying to create the basket. Please contact support."
				);
			});
		setFormStatus("idle");
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>
				Cart
			</Typography>
			<Box
				display={"flex"}
				flexDirection={"column"}
				alignItems={"center"}
				justifyContent={"center"}
				width={"100%"}
			>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Item</TableCell>
								<TableCell>Notes</TableCell>
								<TableCell>Price</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{cart.map((item) => {
								return (
									<TableRow key={item.menu_item_id}>
										<TableCell>
											<Box
												display={"flex"}
												flexDirection={"column"}
												alignItems={"center"}
												justifyContent={"center"}
											>
												<Typography variant="h6">{item.name}</Typography>
												{
													//image of the item

													item?.image_url ? (
														<img
															src={item.image_url}
															alt={item.name}
															style={{ width: "100px" }}
														/>
													) : null
												}
											</Box>
										</TableCell>
										<TableCell>
											<Box
												display={"flex"}
												flexDirection={"column"}
												alignItems={"center"}
												justifyContent={"center"}
											>
												{
													//map through the selectedIngredients array and display the ingredients
													//if the item is in the ingredients array but not in the selectedIngredients array, display a red X and the ingredient
													//if the item is in the selectedIngredients array, display a green check and the ingredient

													item?.ingredients?.map((ingredient) => {
														return (
															<Box
																key={ingredient}
																padding={1}
																display={"flex"}
																flexDirection={"row"}
																alignItems={"center"}
																justifyContent={"center"}
															>
																{!item?.selectedIngredients?.includes(
																	ingredient
																) ? (
																	<Box
																		display={"flex"}
																		flexDirection={"row"}
																		alignItems={"center"}
																		justifyContent={"center"}
																	>
																		<Typography>
																			{"No "} {ingredient}
																		</Typography>

																		<Close
																			sx={{
																				color: "red",
																			}}
																		/>
																	</Box>
																) : null}
															</Box>
														);
													})
												}
												<Typography>{item?.notes}</Typography>
											</Box>
										</TableCell>

										<TableCell>
											<Box
												display={"flex"}
												flexDirection={"row"}
												alignItems={"center"}
												justifyContent={"center"}
											>
												<CurrencyFormat
													value={item.price}
													displayType={"text"}
													thousandSeparator={true}
													prefix={"$"}
												></CurrencyFormat>
												<Close
													//on hover over the icon, change the color to red
													sx={{
														"&:hover": {
															color: "red",
															//cursor is hand
															cursor: "pointer",
														},
													}}
													onClick={() => {
														//remove the item from the cart
														setCart((prev) =>
															prev.filter((cartItem) => cartItem !== item)
														);
													}}
												/>
											</Box>
										</TableCell>
									</TableRow>
								);
							})}
							<TableRow>
								<TableCell />

								<TableCell>
									<Typography variant="h6">Subtotal:</Typography>
								</TableCell>
								<TableCell>
									<CurrencyFormat
										value={calculateBalance(cart)}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
									></CurrencyFormat>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Box>

			<Box
				padding={1}
				//make full width

				width={"100%"}
			>
				<TextField
					fullWidth
					label="Email"
					variant="outlined"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
			</Box>

			{formStatus === "success" ? (
				<Box padding={1} borderRadius={2} backgroundColor={"#4caf50"}>
					<Alert
						iconMapping={{
							success: <CheckCircleOutlineIcon fontSize="inherit" />,
						}}
					>
						Navigating to payment page...
					</Alert>
				</Box>
			) : null}
			{
				//if formStatus is error, display the error message
				formStatus === "error" ? (
					<Box padding={1} borderRadius={2} backgroundColor={"#f44336"}>
						<Alert severity="error">{errorMessage}</Alert>
					</Box>
				) : null
			}
			<Box padding={1}>
				{formStatus === "loading" || formStatus === "success" ? (
					<CircularProgress />
				) : (
					<Button
						onClick={handleCheckout}
						variant="contained"
						sx={{ margin: "1rem" }}
						disabled={
							cart.length === 0 ||
							formStatus === "success" ||
							formStatus === "loading"
						}
					>
						Checkout
					</Button>
				)}
			</Box>
		</Box>
	);
}
//Compare this snippet from src/Components/Cart/Cart.js:
