import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { Box, CircularProgress, Typography, Button } from "@mui/material";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import TruckInFocus from "./TruckInFocus";
import { Add } from "@mui/icons-material";
import CustomPopover from "../CustomPopover/CustomPopover";
import AddTruck from "./MenuActions/AddTruck";
import Alert from "@mui/material/Alert";
import Helmet from "react-helmet";
import ReactGA from "react-ga4";

export default function ManageTrucks() {
	const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
	const [trucksData, setTrucksData] = useState(null);
	const [truckInFocus, setTruckInFocus] = useState(null);
	const [formStatus, setFormStatus] = useState("idle");
	const [addTruckPopover, setAddTruckPopover] = useState(null);
	const [truckToDelete, setTruckToDelete] = useState(null);

	const deleteTruck = async () => {
		const token = await getAccessTokenSilently();
		setFormStatus("deletingTruck");
		axios
			.delete(
				process.env.REACT_APP_BACKEND_URL +
					`/deleteTruck/${truckToDelete?.truck_id}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				setFormStatus("truckDeleted");
				setTrucksData(
					trucksData.filter((t) => t?.truck_id !== truckToDelete?.truck_id)
				);
				setTimeout(() => {
					setFormStatus("idle");
					setTruckToDelete(null);
				}, 1000);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		setFormStatus("loading");
		const getUserData = async () => {
			if (isAuthenticated && user) {
				const token = await getAccessTokenSilently();
				axios
					.get(
						process.env.REACT_APP_BACKEND_URL + `/getTrucksBySub/${user?.sub}`,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then((res) => {
						setFormStatus("idle");
						if (res?.data) {
							setTrucksData(res?.data);
						} else {
							//If the user has no truck data, set truckData to an empty array
							setTrucksData([]);
						}
					})
					.catch((error) => {
						console.log(error);
					});
			}
		};

		getUserData();
	}, [isAuthenticated, user, getAccessTokenSilently]);

	//Google Analytics
	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/manageTrucks",
			title: "Manage Trucks",
		});
	}, []);

	// Render truck data
	return (
		<Box>
			<Helmet>
				<title>Manage Trucks</title>
				<meta
					name="description"
					content="The easiest way to manage your food trucks"
				/>
			</Helmet>

			{
				//if there is truck data and a truck is in focus, render the truck detail page
				truckInFocus ? (
					<TruckInFocus
						truckInFocus={truckInFocus}
						setTruckInFocus={setTruckInFocus}
					/>
				) : null
			}
			{addTruckPopover ? (
				<CustomPopover setPopoverVisible={setAddTruckPopover}>
					<AddTruck
						setTrucksData={setTrucksData}
						setAddTruckPopover={setAddTruckPopover}
					></AddTruck>
				</CustomPopover>
			) : null}
			{truckToDelete ? (
				<CustomPopover setPopoverVisible={setTruckToDelete}>
					<Box
						display={"flex"}
						flexDirection={"column"}
						alignItems={"center"}
						justifyContent={"center"}
					>
						<Box padding={1}>
							<Typography variant="h6">
								Are you sure you want to delete this truck?
							</Typography>
							<Typography variant="body1">
								This action can't be undone.
							</Typography>
						</Box>
						{formStatus === "deletingTruck" ? (
							<Box padding={1}>
								<CircularProgress />
							</Box>
						) : formStatus === "truckDeleted" ? (
							<Box padding={1}>
								<Alert severity="success">Truck Deleted</Alert>
							</Box>
						) : null}

						<Box
							display={"flex"}
							flexDirection={"row"}
							alignItems={"center"}
							justifyContent={"center"}
						>
							<Box padding={1}>
								<Button
									variant="contained"
									color="error"
									onClick={() => {
										deleteTruck();
									}}
								>
									Delete
								</Button>
							</Box>
							<Box padding={1}>
								<Button
									variant="contained"
									color="warning"
									onClick={() => {
										setTruckToDelete(null);
									}}
								>
									Cancel
								</Button>
							</Box>
						</Box>
					</Box>
				</CustomPopover>
			) : null}

			{
				//if there is truck data and no truck is in focus, render the trucks
				trucksData?.length > 0 && !truckInFocus ? (
					<Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
						<Typography textAlign={"center"} variant="h2">
							Manage Your Trucks
						</Typography>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Truck Name</TableCell>
										<TableCell>Description</TableCell>
										<TableCell>Food Type</TableCell>
										<TableCell>Delete</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{trucksData?.map((truck) => {
										return (
											<TableRow
												key={truck?.truck_id}
												//on hover make white nad pointer
												sx={{
													"&:hover": {
														backgroundColor: "white",
														cursor: "pointer",
													},
												}}
												//onClick open the truck edit page
											>
												<TableCell
													onClick={() => {
														setTruckInFocus(truck);
													}}
												>
													{truck?.name}
												</TableCell>
												<TableCell
													onClick={() => {
														setTruckInFocus(truck);
													}}
												>
													{truck?.description}
												</TableCell>
												<TableCell
													onClick={() => {
														setTruckInFocus(truck);
													}}
												>
													{truck?.food_type}
												</TableCell>
												<TableCell>
													<Button
														variant="contained"
														color="primary"
														onClick={() => {
															setTruckToDelete(truck);
														}}
													>
														Delete
													</Button>
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
						<Box padding={1}>
							<Button
								variant="contained"
								color="primary"
								startIcon={<Add></Add>}
								onClick={() => {
									setAddTruckPopover(true);
								}}
							>
								Add Truck
							</Button>
						</Box>
					</Box>
				) : truckInFocus ? null : formStatus === "loading" ? (
					<Box
						width={"100%"}
						display={"flex"}
						justifyContent={"center"}
						alignItems={"center"}
					>
						<CircularProgress />
					</Box>
				) : (
					<Box
						display={"flex"}
						flexDirection={"column"}
						alignItems={"center"}
						justifyContent={"center"}
					>
						<Typography textAlign={"center"} variant="h2">
							No Trucks Found
						</Typography>
						<Box padding={1}>
							<Button
								variant="contained"
								color="primary"
								startIcon={<Add></Add>}
								onClick={() => {
									setAddTruckPopover(true);
								}}
							>
								Add Truck
							</Button>
						</Box>
					</Box>
				)
			}
		</Box>
	);
}
