//This will be the payment screen with the stripe payment form

import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
	Table,
	TableContainer,
	TableHead,
	TableBody,
	Typography,
	TableRow,
	TableCell,
	TableFooter,
	Divider,
} from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import CheckoutForm from "./CheckoutForm";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ReactGA from "react-ga4";

export default function PaymentScreen() {
	const { clientSecret, orderNumber } = useParams();
	const [order, setOrder] = useState(null);
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

	const appearance = {
		theme: "stripe",
	};
	const options = {
		clientSecret,
		appearance,
	};

	//get the order from the backend
	useEffect(() => {
		orderNumber &&
			axios
				.get(`${process.env.REACT_APP_BACKEND_URL}/orders/${orderNumber}`)
				.then((res) => {
					setOrder(res?.data);
				});
	}, [orderNumber]);

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: `/payment/${orderNumber}`,
			title: "Payment",
		});
	}, [orderNumber]);

	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			alignItems={"center"}
			justifyContent={"center"}
		>
			<Helmet>
				<title>Checkout</title>
				<meta name="description" content="Checkout" />

				<meta
					name="keywords"
					content="food, truck, order, line, feast, no line, no line feast"
				/>

				<meta name="author" content="No Line Feast" />
			</Helmet>
			<Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>
				Checkout
			</Typography>
			<Box width={"100%"} padding={2}>
				{
					<TableContainer>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell>Item</TableCell>
									<TableCell>Notes</TableCell>
									<TableCell>Price</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{order?.items?.map(
									(row, index) =>
										//convert the row to json
										(row = JSON.parse(row)) && (
											<TableRow key={row}>
												<TableCell component="th" scope="row">
													{row.name}
												</TableCell>
												<TableCell>
													{
														//list all ingredients that are in the parsedItem.ingredients array but not in the parsedItem.selectedIngredients array
														row.ingredients
															.filter(
																(ingredient) =>
																	!row.selectedIngredients.includes(ingredient)
															)
															.map((ingredient) => {
																return (
																	<Box key={ingredient}>
																		<Typography
																			style={{
																				color: "red",
																			}}
																		>
																			{"Remove " + ingredient}
																		</Typography>
																	</Box>
																);
															})
													}
													{row.notes}
												</TableCell>

												<TableCell>{row.price}</TableCell>
											</TableRow>
										)
								)}
							</TableBody>
						</Table>
						<Divider
							style={{
								backgroundColor: "black",
							}}
						/>
						<Table size="small">
							<TableFooter>
								<TableRow>
									<TableCell colSpan={2}>
										<Typography variant="body1" textAlign={"right"}>
											Subtotal
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="body1" textAlign={"center"}>
											{order?.total}
										</Typography>
									</TableCell>
								</TableRow>

								<TableRow>
									<TableCell colSpan={2}>
										<Typography variant="body1" textAlign={"right"}>
											Service Fee
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="body1" textAlign={"center"}>
											{(order?.total * 0.07).toFixed(2)}
										</Typography>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell colSpan={2}>
										<Typography variant="body1" textAlign={"right"}>
											Tax
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="body1" textAlign={"center"}>
											{(order?.total * 0.1).toFixed(2)}
										</Typography>
									</TableCell>
								</TableRow>

								<TableRow>
									<TableCell colSpan={2}>
										<Typography variant="h6" gutterBottom textAlign={"center"}>
											Total
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="h6" gutterBottom textAlign={"center"}>
											{(order?.total * 1.17).toFixed(2)}
										</Typography>
									</TableCell>
								</TableRow>
							</TableFooter>
						</Table>
					</TableContainer>
				}
			</Box>
			<Box
				display={"flex"}
				flexDirection={"column"}
				alignItems={"center"}
				justifyContent={"center"}
				padding={2}
			>
				<Typography variant="h5" gutterBottom style={{ textAlign: "center" }}>
					Please enter your payment information below
				</Typography>
			</Box>
			<Box
				display={"flex"}
				flexDirection={"column"}
				alignItems={"center"}
				justifyContent={"center"}
				padding={2}
			>
				<Elements options={options} stripe={stripePromise}>
					<CheckoutForm clientSecret={clientSecret} orderNumber={orderNumber} />
				</Elements>
			</Box>
		</Box>
	);
}
