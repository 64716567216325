//This will be the truck detail page where the user can see the details of the truck and order from it
//It will also display the truck menu, a small cart icon in the bottom right corner that will bring up their cart, and a button to order from the truck

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Box, Button, Typography } from "@mui/material";
import CustomerMenu from "../Menu/CustomerMenu";
import { ShoppingCart } from "@mui/icons-material";
import CustomPopover from "../CustomPopover/CustomPopover";
import Cart from "../Cart/Cart";
import ModifyItemAddToCart from "../ModifyItemAddToCart/ModifyItemAddToCart";
import Loading from "../Loading/Loading";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";

export default function TruckDetail() {
	const { id } = useParams();
	const [truckData, setTruckData] = useState({});
	const [cart, setCart] = useState([]);
	const [cartPopover, setCartPopover] = useState(false);
	const [addToCartPopover, setAddToCartPopover] = useState(false);
	const [addToCartItem, setAddToCartItem] = useState({});
	const [formStatus, setFormStatus] = useState("idle");

	useEffect(() => {
		setFormStatus("loading");
		axios
			.get(process.env.REACT_APP_BACKEND_URL + `/trucks/${id}`)
			.then((res) => {
				setTruckData(res?.data);
			})
			.then(() => {
				setFormStatus("idle");
			})
			.catch((error) => {
				setFormStatus("error");
				console.log(error);
			});
	}, [id]);

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: `/truck/${id}`,
			title: "truck detail page",
		});
	}, [id]);

	return (
		<Box>
			<Helmet>
				<title>{truckData?.name || "No Line Feast"}</title>
				<meta
					name="description"
					content="The easiest way to order from your favorite food trucks"
				/>

				<meta
					property="og:title"
					content={truckData?.name || "No Line Feast"}
				/>
				<meta
					property="og:description"
					content="The easiest way to order from your favorite food trucks without ever standing in line"
				/>
			</Helmet>

			{cartPopover ? (
				<CustomPopover setPopoverVisible={setCartPopover}>
					<Cart cart={cart} setCart={setCart} truck_id={id}></Cart>
				</CustomPopover>
			) : null}

			{addToCartPopover ? (
				<CustomPopover setPopoverVisible={setAddToCartPopover}>
					<ModifyItemAddToCart
						menu_item={addToCartItem}
						setCart={setCart}
						setPopoverVisible={setAddToCartPopover}
					/>
				</CustomPopover>
			) : null}

			{formStatus === "loading" ? (
				<Loading />
			) : (
				<Box>
					<Box
						display={"flex"}
						flexDirection={"column"}
						alignItems={"center"}
						justifyContent={"center"}
					>
						<Typography
							variant="h2"
							gutterBottom
							//center the text
							style={{ textAlign: "center" }}
						>
							{truckData?.name || "No Truck Found"}
						</Typography>
						<Typography
							variant="h5"
							gutterBottom
							style={{ textAlign: "center" }}
						>
							{truckData?.food_type || "No description found"}
						</Typography>

						<CustomerMenu
							truckData={truckData}
							setCart={setCart}
							setAddToCartPopover={setAddToCartPopover}
							setAddToCartItem={setAddToCartItem}
						/>
					</Box>

					<Box
						//absolute positioning in the bottom right corner
						position={"fixed"}
						bottom={"1rem"}
						right={"1rem"}
					>
						{cart.length > 0 ? (
							<Box
								position={"absolute"}
								top={"-10px"}
								right={"-10px"}
								bgcolor={"red"}
								color={"white"}
								borderRadius={"50%"}
								width={"30px"}
								height={"30px"}
								display={"flex"}
								justifyContent={"center"}
								alignItems={"center"}
								zIndex={2}
							>
								<Typography
									variant="h6"
									gutterBottom
									style={{ textAlign: "center" }}
								>
									{cart.length}
								</Typography>
							</Box>
						) : null}

						<Button
							variant="contained"
							onClick={() =>
								setCartPopover((prev) => {
									return !prev;
								})
							}
							//make a round circle
							sx={{
								borderRadius: "50%",
								width: "60px",
								height: "60px",
							}}
						>
							<ShoppingCart />
						</Button>
					</Box>
				</Box>
			)}
		</Box>
	);
}
