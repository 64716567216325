import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useParams } from "react-router";
import axios from "axios";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";

export default function OrderConfirmation() {
	const { orderNumber } = useParams();
	//get the payment_intent from the URL
	const [order, setOrder] = useState(null);

	useEffect(() => {
		//get the order from the backend
		axios
			.get(process.env.REACT_APP_BACKEND_URL + `/orders/${orderNumber}`)
			.then((res) => {
				setOrder(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [orderNumber]);

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: `/orderConfirmation/${orderNumber}`,
			title: "Order Confirmation",
		});
	}, [orderNumber]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				height: "100vh",
			}}
		>
			<Helmet>
				<title>Order Confirmation</title>
				<meta name="description" content="Order Confirmation" />
				<meta
					name="keywords"
					content="food, truck, order, line, feast, no line, no line feast"
				/>
			</Helmet>
			<h1>Order Confirmation</h1>
			<h2>Order number: {orderNumber}</h2>
			{order?.status === "Created" ? (
				<Typography variant="h5" gutterBottom textAlign={"center"}>
					Your order has been created.
				</Typography>
			) : order?.status === "Paid" ? (
				<Typography variant="h5" gutterBottom textAlign={"center"}>
					Confirmed! Your order has been paid and should be on its way soon.
				</Typography>
			) : order?.status === "Completed" ? (
				<Typography variant="h5" gutterBottom textAlign={"center"}>
					Your order is complete. Enjoy your meal!
				</Typography>
			) : (
				<h3>{order?.status}</h3>
			)}
		</Box>
	);
}
