//This will be the component that allows the user to edit any of the truck details

import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

export default function EditTruckDetails(props) {
	const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
	const { truckInFocus, setTruckInFocus, setEditTruckPopover } = props;
	const [editTruck, setEditTruck] = useState(truckInFocus);
	const [formStatus, setFormStatus] = useState("idle");

	const handleEditTruck = () => {
		setFormStatus("loading");

		const editTruckData = async () => {
			if (isAuthenticated && user) {
				const token = await getAccessTokenSilently();
				axios
					.post(
						process.env.REACT_APP_BACKEND_URL +
							`/editTruck/${truckInFocus?.truck_id}`,
						editTruck,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then((res) => {
						setFormStatus("success");
						setTimeout(() => {
							setTruckInFocus(res?.data);
							setEditTruckPopover(false);
							setFormStatus("idle");
						}, 1000);
					})

					.catch((error) => {
						setFormStatus("error");
						console.log(error);
					});
			}
		};
		editTruckData();
	};

	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			alignItems={"center"}
			justifyContent={"center"}
		>
			<Typography padding={1} variant="h2">
				Edit Truck
			</Typography>
			<Box padding={1}>
				<TextField
					label="Truck Name"
					value={editTruck?.name}
					onChange={(e) => {
						setEditTruck({ ...editTruck, name: e.target.value });
					}}
				/>
			</Box>
			<Box padding={1}>
				<TextField
					label="Description"
					value={editTruck?.description}
					onChange={(e) => {
						setEditTruck({ ...editTruck, description: e.target.value });
					}}
				/>
			</Box>
			<Box padding={1}>
				<TextField
					label="Food Type"
					value={editTruck?.food_type}
					onChange={(e) => {
						setEditTruck({ ...editTruck, food_type: e.target.value });
					}}
				/>
			</Box>
			<Box padding={1}>
				<TextField
					label="Phone Number"
					value={editTruck?.phone_number}
					onChange={(e) => {
						setEditTruck({ ...editTruck, phone_number: e.target.value });
					}}
				/>
			</Box>
			<Box padding={1}>
				<TextField
					label="Home Zip Code"
					value={editTruck?.home_zip}
					onChange={(e) => {
						setEditTruck({ ...editTruck, home_zip: e.target.value });
					}}
				/>
			</Box>
			<Box padding={1}>
				<TextField
					label="Notes"
					multiline
					rows={2}
					value={editTruck?.truck_notes}
					onChange={(e) => {
						setEditTruck({ ...editTruck, notes: e.target.value });
					}}
				/>
			</Box>
			{formStatus === "success" ? (
				<Box padding={1}>
					<Alert severity="success">Truck Updated!</Alert>
				</Box>
			) : null}
			<Box padding={1}>
				<Button
					variant="contained"
					color="primary"
					startIcon={<Add />}
					onClick={handleEditTruck}
				>
					Edit Truck
				</Button>
			</Box>
		</Box>
	);
}
