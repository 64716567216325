//This will be the component that shows all of the menus for a specific truck. On this page there should
//be the ability to edit any menu, add a new menu, and delete a menu

import React, { useEffect, useState } from "react";

import axios from "axios";
import { Box, Button, TableContainer, Typography } from "@mui/material";
import { Add, Edit } from "@mui/icons-material";
import CustomPopover from "../CustomPopover/CustomPopover";
import EditMenu from "./MenuActions/EditMenu";
import AddMenu from "./MenuActions/AddMenu";
import DeleteMenu from "./MenuActions/DeleteMenu";
import AddMenuItem from "./MenuActions/AddMenuItem";
import Loading from "../Loading/Loading";
import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import EditMenuItems from "./MenuActions/EditMenuItems";

export default function ViewTruckMenus(props) {
	const { truckInFocus, setTruckInFocus, setViewTruckMenuPopover } = props;
	const [menus, setMenus] = useState([]);
	const [editMenuPopover, setEditMenuPopover] = useState(null);
	const [addMenuPopover, setAddMenuPopover] = useState(null);
	const [deleteMenuPopover, setDeleteMenuPopover] = useState(null);
	const [addMenuItemPopover, setAddMenuItemPopover] = useState(null);
	const [editMenuItemsPopover, setEditMenuItemsPopover] = useState(null);
	const [formStatus, setFormStatus] = useState("idle");

	useEffect(() => {
		const fetchMenus = () => {
			setFormStatus("loading");
			axios
				.get(
					process.env.REACT_APP_BACKEND_URL + `/menus/${truckInFocus?.truck_id}`
				)
				.then((res) => {
					setMenus(res?.data);
				})
				.then(() => {
					setFormStatus("idle");
				})
				.catch((error) => {
					setFormStatus("error");
					console.log(error);
				});
		};

		if (
			!addMenuPopover &&
			!editMenuPopover &&
			!addMenuItemPopover &&
			!deleteMenuPopover &&
			!editMenuItemsPopover
		) {
			fetchMenus();
		}
	}, [
		truckInFocus,
		addMenuPopover,
		addMenuItemPopover,
		deleteMenuPopover,
		editMenuItemsPopover,
		editMenuPopover,
	]);

	return (
		<Box>
			{editMenuPopover ? (
				<CustomPopover setPopoverVisible={setEditMenuPopover}>
					<EditMenu
						truckInFocus={truckInFocus}
						setTruckInFocus={setTruckInFocus}
						menuInFocus={editMenuPopover}
						setMenuInFocus={setEditMenuPopover}
					></EditMenu>
				</CustomPopover>
			) : null}

			{addMenuItemPopover ? (
				<CustomPopover setPopoverVisible={setAddMenuItemPopover}>
					<AddMenuItem
						truckInFocus={truckInFocus}
						setTruckInFocus={setTruckInFocus}
						setAddMenuItemPopover={setAddMenuItemPopover}
					></AddMenuItem>
				</CustomPopover>
			) : null}
			{deleteMenuPopover ? (
				<CustomPopover setPopoverVisible={setDeleteMenuPopover}>
					<DeleteMenu
						truckInFocus={truckInFocus}
						setTruckInFocus={setTruckInFocus}
						menuInFocus={deleteMenuPopover}
						setMenuInFocus={setDeleteMenuPopover}
					></DeleteMenu>
				</CustomPopover>
			) : null}
			{formStatus === "loading" ? (
				<Loading />
			) : // if the addMenuPopover is true, show the AddMenu component
			addMenuPopover ? (
				<AddMenu
					truckInFocus={truckInFocus}
					setTruckInFocus={setTruckInFocus}
					setAddMenuPopover={setAddMenuPopover}
				></AddMenu>
			) : editMenuItemsPopover ? (
				<EditMenuItems
					truckInFocus={truckInFocus}
					setTruckInFocus={setTruckInFocus}
					setEditMenuItemsPopover={setEditMenuItemsPopover}
				/>
			) : (
				<Box>
					<Box
						//position in the top left corner using MUI
						display={"flex"}
						justifyContent={"flex-start"}
						paddingTop={"10px"}
						paddingLeft={"10px"}
					>
						<ArrowBack
							sx={{ "&:hover": { color: "white", cursor: "pointer" } }}
							onClick={() => {
								setViewTruckMenuPopover(false);
							}}
						/>
					</Box>
					<Box
						display={"flex"}
						flexDirection={"column"}
						alignItems={"center"}
						justifyContent={"center"}
					>
						<Typography variant="h2">Menus</Typography>
						<Box>
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>ID</TableCell>
											<TableCell>Menu Name</TableCell>
											<TableCell
												style={{
													display: "flex",
													justifyContent: "center",
												}}
											>
												Actions
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{menus?.map((menu) => (
											<TableRow key={menu?.menu_id}>
												<TableCell>{menu?.menu_id}</TableCell>
												<TableCell>{menu?.name}</TableCell>
												<TableCell>
													<Button onClick={() => setEditMenuPopover(menu)}>
														Edit
													</Button>
													<Button onClick={() => setDeleteMenuPopover(menu)}>
														Delete
													</Button>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
						<Box display={"flex"} justifyContent={"space-evenly"} padding={1}>
							<Box padding={1}>
								<Button
									variant="contained"
									onClick={() => setAddMenuPopover(true)}
									startIcon={<Add />}
								>
									Menu
								</Button>
							</Box>

							<Box padding={1}>
								<Button
									variant="contained"
									onClick={() => setEditMenuItemsPopover(true)}
									startIcon={<Edit />}
								>
									Menu Items
								</Button>
							</Box>
						</Box>
					</Box>
				</Box>
			)}
		</Box>
	);
}
