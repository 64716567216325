import React, { useState } from "react";

import { Drawer, List, ListItem, ListItemText } from "@mui/material";
import { Fragment } from "react";

import { IconButton, Link } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

function NavBarDrawer(props) {
	const { dashboardLink } = props;
	const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

	const theme = useTheme();

	const [openDrawer, setOpenDrawer] = useState(false);
	return (
		<Fragment>
			<Drawer
				open={openDrawer}
				variant="temporary"
				onClose={() => setOpenDrawer(false)}
				bgcolor={theme.palette.primary.main}
			>
				<Close
					onClick={() => setOpenDrawer(false)}
					style={{ cursor: "pointer" }}
				/>
				<List>
					<ListItem onClick={() => setOpenDrawer(false)}>
						<ListItemText>
							<Link href="/">Home</Link>
						</ListItemText>
					</ListItem>
					<ListItem onClick={() => setOpenDrawer(false)}>
						<ListItemText>
							<Link href="/aboutUs">About</Link>
						</ListItemText>
					</ListItem>

					{isAuthenticated ? (
						<ListItem onClick={() => setOpenDrawer(false)}>
							<ListItemText>
								<Link href={dashboardLink}>Manage Trucks</Link>
							</ListItemText>
						</ListItem>
					) : null}

					{isAuthenticated ? (
						<ListItem onClick={() => setOpenDrawer(false)}>
							<Fragment>
								<Button
									variant="text"
									color="secondary"
									onClick={() =>
										logout({
											logoutParams: {
												returnTo: window.location.origin,
											},
										})
									}
								>
									Logout
								</Button>
							</Fragment>
						</ListItem>
					) : (
						<Button
							variant="text"
							color="secondary"
							onClick={() => loginWithRedirect()}
						>
							Truck Login
						</Button>
					)}
				</List>
			</Drawer>
			<IconButton onClick={() => setOpenDrawer(!openDrawer)}>
				<MenuIcon />
			</IconButton>
		</Fragment>
	);
}
export default NavBarDrawer;
