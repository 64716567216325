//Build the navbar to have the logo on the far left, 64 pixels tall, and 64 pixels wide
//The logo should be clickable and take the user to the home page
//The navbar should have a link to the home page on the to the right of the logo
//The navbar should have a link to the user dashboard on the right side of the home page link
//The navbar should have an avatar icon with a link to the login page on the right side of the user dashboard link
//The navbar should have a logout button on the right side of the avatar icon
//If the screen width is less than 600 pixels, the navbar should be a hamburger menu
//If the screen width is greater than 600 pixels, the navbar should be a full navbar

import React, { Fragment } from "react";
import {
	AppBar,
	Toolbar,
	Typography,
	Link,
	Button,
	Avatar,
	Box,
	Divider,
} from "@mui/material";
import "./NavBar.css";
import { useAuth0 } from "@auth0/auth0-react";
import { useTheme } from "@mui/material/styles";
import NavBarDrawer from "./NavBarDrawer";
import { useMediaQuery } from "@mui/material";

export default function NavBar() {
	const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	return isMobile ? (
		<NavBarDrawer dashboardLink={"/manageTrucks"} />
	) : (
		<Fragment>
			<AppBar
				position="static"
				style={{
					backgroundColor: theme.palette.primary.main,
					height: "64px",
				}}
			>
				<Toolbar
					style={{
						display: "flex",
						justifyContent: "space-between",

						alignItems: "center",
						height: "64px",
					}}
				>
					<Box
						display={"flex"}
						justifyContent={"flex-start"}
						alignItems={"center"}
						height={"100%"}
					>
						<Link
							href="/"
							display={"flex"}
							justifyContent={"flex-start"}
							alignItems={"center"}
						>
							<Typography
								variant="h6"
								sx={{
									color: theme.palette.secondary.main,
									fontWeight: "bold",

									//on hover, make white
									"&:hover": {
										color: theme.palette.primary.textWhite,
										//cursor is hand
										cursor: "pointer",
									},
								}}
							>
								Home
							</Typography>
						</Link>
						<Box
							height={"30%"}
							display={"flex"}
							justifyContent={"flex-start"}
							alignItems={"center"}
							marginLeft={"10px"}
							marginRight={"10px"}
						>
							<Divider orientation="vertical" flexItem color={"black"} />
						</Box>
						<Link
							href="/aboutUs"
							display={"flex"}
							justifyContent={"flex-start"}
							alignItems={"center"}
						>
							<Typography
								variant="h6"
								sx={{
									color: theme.palette.secondary.main,
									fontWeight: "bold",

									//on hover, make white
									"&:hover": {
										color: theme.palette.primary.textWhite,
										//cursor is hand
										cursor: "pointer",
									},
								}}
							>
								About
							</Typography>
						</Link>
						{/*Add in a vertical MUI Divider that is 75% of the height of the navbar*/}
						{isAuthenticated && (
							<Box
								height={"30%"}
								display={"flex"}
								justifyContent={"flex-start"}
								alignItems={"center"}
								marginLeft={"10px"}
								marginRight={"10px"}
							>
								<Divider orientation="vertical" flexItem color={"black"} />
							</Box>
						)}
						{isAuthenticated && (
							<Link
								href="/manageTrucks"
								display={"flex"}
								justifyContent={"flex-start"}
								alignItems={"center"}
							>
								<Typography
									variant="h6"
									sx={{
										color: theme.palette.secondary.main,
										fontWeight: "bold",

										//on hover, make white
										"&:hover": {
											color: theme.palette.primary.textWhite,
											//cursor is hand
											cursor: "pointer",
										},
									}}
								>
									Manage Trucks
								</Typography>
							</Link>
						)}
					</Box>

					<Box
						style={{
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "center",
						}}
					>
						{isAuthenticated ? (
							<Fragment>
								<Link
									href={"/manageTrucks"}
									style={{
										color: theme.palette.secondary.main,
										marginRight: "10px",
									}}
								>
									{" "}
									<Avatar
										alt="User Avatar"
										src={user?.picture}
										style={{
											marginRight: "10px",
										}}
									></Avatar>
								</Link>

								<Button
									variant="text"
									sx={{
										color: theme.palette.secondary.main,
										fontWeight: "bold",

										//on hover, make white
										"&:hover": {
											color: theme.palette.primary.textWhite,
											//cursor is hand
											cursor: "pointer",
										},
									}}
									onClick={() =>
										logout({
											logoutParams: {
												returnTo: window.location.origin,
											},
										})
									}
								>
									Logout
								</Button>
							</Fragment>
						) : (
							<Button
								variant="text"
								sx={{
									color: theme.palette.secondary.main,
									fontWeight: "bold",

									//on hover, make white
									"&:hover": {
										color: theme.palette.primary.textWhite,
										//cursor is hand
										cursor: "pointer",
									},
								}}
								onClick={() => loginWithRedirect()}
							>
								Truck Login
							</Button>
						)}
					</Box>
				</Toolbar>
			</AppBar>
		</Fragment>
	);
}
