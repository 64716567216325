//This will be a page for the user to add a menu item that they can add to their truck
//This page should have a form to add a menu item, and a button to submit the form

import React, { useState } from "react";
import axios from "axios";
import { Box, TextField, Button, Typography, Input } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import { Alert } from "@mui/material";
import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableContainer,
} from "@mui/material";

import { Grid } from "@material-ui/core";

export default function AddMenuItem(props) {
	const { setAddMenuItemPopover } = props;
	const [formStatus, setFormStatus] = useState("idle");
	const [menuItem, setMenuItem] = useState({
		name: "",
		description: "",
		price: 0,
		ingredients: [""],
		menuItemPhoto: null,
	});
	const { user } = useAuth0();

	const handleMenuItemChange = (e) => {
		setMenuItem({ ...menuItem, [e.target.name]: e.target.value });
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setMenuItem({ ...menuItem, menuItemPhoto: file });
	};

	const addMenuItem = () => {
		//if all the fields are not filled out, return an error
		if (
			!menuItem.name ||
			!menuItem.description ||
			!menuItem.price ||
			!menuItem.menuItemPhoto
		) {
			setFormStatus("error");
			return;
		}
		setFormStatus("loading");

		const formData = new FormData();
		formData.append("image", menuItem.menuItemPhoto);
		formData.append("menuItem", JSON.stringify(menuItem));
		formData.append("owner_sub", user.sub);

		axios
			.post(process.env.REACT_APP_BACKEND_URL + `/createMenuItem`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((res) => {
				setFormStatus("success");
				setTimeout(() => {
					setFormStatus("idle");
					if (setAddMenuItemPopover) {
						setAddMenuItemPopover(false);
					}
				}, 1000);
			})
			.catch((error) => {
				setFormStatus("error");
				console.log(error);
			});
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Box>
				<Typography variant="h4" gutterBottom>
					Create Menu Item
				</Typography>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							error={formStatus === "error" && !menuItem.name}
							label="Item Name"
							name="name"
							value={menuItem.name}
							onChange={handleMenuItemChange}
						></TextField>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							error={formStatus === "error" && !menuItem.price}
							label="Price"
							name="price"
							value={menuItem.price}
							onChange={handleMenuItemChange}
						></TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							error={formStatus === "error" && !menuItem.description}
							label=" Description"
							name="description"
							multiline
							rows={2}
							value={menuItem.description}
							onChange={handleMenuItemChange}
						></TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Box
							padding={1}
							//include a border around the box
							border={1}
							borderColor={"gray"}
							borderRadius={1}
						>
							<Typography variant="h6">Photo</Typography>
							<Input
								error={formStatus === "error" && !menuItem.menuItemPhoto}
								type="file"
								inputProps={{ accept: "image/*" }}
								onChange={handleFileChange}
							/>
							{
								//add a small alert if the form is not filled out
								formStatus === "error" && !menuItem.menuItemPhoto && (
									<Alert severity="error">You must upload a photo</Alert>
								)
							}
						</Box>
					</Grid>

					<Grid item xs={12}>
						<Typography variant="h6">Ingredients</Typography>
						<TableContainer>
							{
								//display the ingredients in a table, with a button at the bottom to add an ingredient and a button next to each ingredient removing the ingreient
							}
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Ingredient</TableCell>
										<TableCell>Remove</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{menuItem?.ingredients?.map((ingredient, index) => {
										return (
											<TableRow key={index}>
												<TableCell>
													<TextField
														label="Ingredient"
														variant="outlined"
														value={ingredient}
														onChange={(e) => {
															let newIngredients = menuItem.ingredients;
															newIngredients[index] = e.target.value;
															setMenuItem({
																...menuItem,
																ingredients: newIngredients,
															});
														}}
													/>
												</TableCell>
												<TableCell>
													<Button
														variant="contained"
														color="secondary"
														onClick={() => {
															let newIngredients = menuItem.ingredients;
															newIngredients.splice(index, 1);
															setMenuItem({
																...menuItem,
																ingredients: newIngredients,
															});
														}}
													>
														<Remove />
													</Button>
												</TableCell>
											</TableRow>
										);
									})}
									<TableRow>
										<TableCell>
											<Typography>Add Ingredient</Typography>
										</TableCell>
										<TableCell>
											<Button
												variant="contained"
												color="primary"
												onClick={() => {
													setMenuItem({
														...menuItem,
														ingredients: [...menuItem.ingredients, ""],
													});
												}}
											>
												<Add />
											</Button>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
					<Grid item xs={12}>
						{formStatus === "success" && (
							<Box padding={1}>
								<Alert severity="success">Item Created!</Alert>
							</Box>
						)}
					</Grid>
					<Grid item xs={12}>
						<Box padding={1}>
							<Button
								variant={"contained"}
								startIcon={<Add />}
								onClick={addMenuItem}
							>
								Create Menu Item
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}
