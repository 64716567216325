//This will be a page to display all orders for a specific truck. On this page there should be the ability to
//view the order details, mark an order as complete, and delete an order

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import CustomPopover from "../CustomPopover/CustomPopover";
import ViewOrderDetails from "./ViewOrderDetails";
import { ArrowBack } from "@mui/icons-material";
import moment from "moment";

import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableContainer,
} from "@mui/material";

export default function TruckOrders(props) {
	const { truckInFocus, setTruckInFocus } = props;
	const [orders, setOrders] = useState([]);
	const [orderInFocus, setOrderInFocus] = useState(null);
	const [formStatus, setFormStatus] = useState("loading");

	useEffect(() => {
		setFormStatus("loading");
		axios
			.get(
				process.env.REACT_APP_BACKEND_URL +
					`/getOrdersByTruck/${truckInFocus?.truck_id}`
			)
			.then((res) => {
				setOrders(res?.data);
			})
			.then(() => {
				setFormStatus("idle");
			})
			.catch((error) => {
				setFormStatus("error");
				console.log(error);
			});
	}, [truckInFocus]);

	return (
		<Box>
			{orderInFocus ? (
				<CustomPopover setPopoverVisible={setOrderInFocus}>
					<ViewOrderDetails
						orderInFocus={orderInFocus}
						setOrderInFocus={setOrderInFocus}
					></ViewOrderDetails>
				</CustomPopover>
			) : null}
			<Box
				display={"flex"}
				justifyContent={"flex-start"}
				paddingTop={"10px"}
				paddingLeft={"10px"}
			>
				<ArrowBack
					sx={{ "&:hover": { color: "white", cursor: "pointer" } }}
					onClick={() => {
						setTruckInFocus(null);
					}}
				/>
			</Box>
			<Box
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				paddingTop={"10px"}
			>
				<Typography variant={"h5"}>All Orders</Typography>
			</Box>
			{formStatus === "idle" ? (
				<Box>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Order ID</TableCell>
									<TableCell>Order Status</TableCell>
									<TableCell>Order Total</TableCell>
									<TableCell>Order Date</TableCell>
									<TableCell>View</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{orders &&
									orders.map((order) => {
										return (
											<TableRow key={order.id}>
												<TableCell>{order.id}</TableCell>
												<TableCell>{order.status}</TableCell>
												<TableCell>{order.total}</TableCell>
												<TableCell>
													{
														//use moment to format the date using UTC -5
														moment(order.created_at)
															.utcOffset(-10)
															.format("MM/DD/YYYY hh:mm A")
													}
												</TableCell>
												<TableCell>
													<Button onClick={() => setOrderInFocus(order)}>
														View
													</Button>
												</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			) : formStatus === "error" ? (
				<Typography variant={"h4"}>Error loading orders</Typography>
			) : (
				<CircularProgress />
			)}
		</Box>
	);
}
