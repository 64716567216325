import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Button, Divider, Typography } from "@material-ui/core";
import { Box, Grid } from "@mui/material";
import FirstStreetTacos from "../../Assets/FirstStreetTacos.jpeg";
import EditBrownies from "../../Assets/EditBrownies.jpeg";
import CreateATruck from "../../Assets/CreateATruck.jpeg";
import Orders from "../../Assets/Orders.jpeg";
import Truck22QRCode from "../../Assets/Truck22QRCode.jpeg";
import Search from "../../Assets/Search.jpeg";
import StreetTacosInCart from "../../Assets/StreetTacosInCart.jpeg";
import PayAndEat from "../../Assets/PayAndEat.jpeg";

import { useAuth0 } from "@auth0/auth0-react";
import ReactGA from "react-ga4";

export default function AboutUs() {
	const { loginWithRedirect } = useAuth0();
	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: `/aboutUs`,
			title: "About Us",
		});
	}, []);
	return (
		<Box padding={2}>
			<Helmet>
				<title>About Us | NoLineFeast</title>
				<meta
					name="description"
					content="NoLineFeast allows you to order from your favorite food trucks without waiting in line. Learn more about us."
				/>
			</Helmet>

			<Grid container spacing={3}>
				<Grid
					item
					xs={12}
					display={"flex"}
					flexDirection={"column"}
					alignItems={"center"}
					justifyContent={"center"}
					textAlign={"center"}
				>
					<Typography variant="h3" gutterBottom>
						No Line Feast
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}

					//center the image
				>
					<img
						src={FirstStreetTacos}
						alt="First Street Tacos"
						style={{
							//if the screen is small, make the image smaller
							width: window.innerWidth < 600 ? "75%" : "25%",
							//center the image
							marginLeft: "auto",
							marginRight: "auto",
							display: "block",
						}}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					display={"flex"}
					flexDirection={"column"}
					alignItems={"center"}
					justifyContent={"center"}
					textAlign={"center"}
				>
					<Typography variant="h4" gutterBottom>
						About NoLineFeast
					</Typography>
					<Typography variant="body1" gutterBottom>
						NoLineFeast is an app that allows you to order from your favorite
						food trucks without waiting in line. We believe in the power of food
						to bring people together, and we're committed to making that
						experience more convenient and enjoyable.
					</Typography>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							loginWithRedirect({
								//redirectURI
								authorizationParams: {
									redirect_uri: window.location.origin + "/manageTrucks",
									screen_hint: "signup",
								},
							});
						}}
					>
						Sign your truck up now!
					</Button>
				</Grid>
				<Grid
					item
					xs={12}
					display={"flex"}
					flexDirection={"column"}
					alignItems={"center"}
					justifyContent={"center"}
					textAlign={"center"}
				>
					<Typography variant="h4" gutterBottom>
						It's super easy to use, here's how it works for customers:
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					md={6} //center the text
					display={"flex"}
					flexDirection={"column"}
					alignItems={"center"}
					justifyContent={"center"}
					textAlign={"center"}
				>
					<Typography
						variant="h6"
						gutterBottom
						flexDirection={"row"}
						alignItems={"center"}
						justifyContent={"center"}
						textAlign={"center"}
					>
						1. Users scan your QR code or search for your truck
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					display={"flex"}
					flexDirection={"row"}
					alignItems={"center"}
					justifyContent={"center"}
				>
					<Box padding={1}>
						<img
							src={Truck22QRCode}
							alt="Scan for your truck"
							style={{
								width: "100%",
								//center the image
								marginLeft: "auto",
								marginRight: "auto",
								display: "block",
							}}
						/>
					</Box>
					<Box padding={1}>
						<img
							src={Search}
							alt="Search for your truck"
							style={{
								width: "100%",
								//center the image
								marginLeft: "auto",
								marginRight: "auto",
								display: "block",
							}}
						/>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					md={6} //center the text
					display={"flex"}
					flexDirection={"column"}
					alignItems={"center"}
					justifyContent={"center"}
					textAlign={"center"}
				>
					<Typography
						variant="h6"
						gutterBottom
						flexDirection={"column"}
						alignItems={"center"}
						justifyContent={"center"}
						textAlign={"center"}
					>
						2. Add items to your cart, adding notes, removing ingredients or
						notifying of allergies as needed
					</Typography>
				</Grid>
				<Grid item xs={12} md={6}>
					<img
						src={StreetTacosInCart}
						alt="Add An Item"
						style={{
							width: "50%",
							//center the image
							marginLeft: "auto",
							marginRight: "auto",
							display: "block",
						}}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={6} //center the text
					display={"flex"}
					flexDirection={"column"}
					alignItems={"center"}
					justifyContent={"center"}
					textAlign={"center"}
				>
					<Typography
						variant="h6"
						gutterBottom
						flexDirection={"column"}
						alignItems={"center"}
						justifyContent={"center"}
						textAlign={"center"}
					>
						3. Pay and eat!
					</Typography>
				</Grid>
				<Grid item xs={12} md={6}>
					<img
						src={PayAndEat}
						alt="Pay and Eat!"
						style={{
							width: "50%",
							//center the image
							marginLeft: "auto",
							marginRight: "auto",
							display: "block",
						}}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					display={"flex"}
					flexDirection={"column"}
					alignItems={"center"}
					justifyContent={"center"}
					textAlign={"center"}
				>
					<Typography variant="h4" gutterBottom>
						And here's how it works for food trucks:
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					md={6} //center the text
					display={"flex"}
					flexDirection={"column"}
					alignItems={"center"}
					justifyContent={"center"}
					textAlign={"center"}
				>
					<Typography
						variant="h6"
						gutterBottom
						flexDirection={"row"}
						alignItems={"center"}
						justifyContent={"center"}
						textAlign={"center"}
					>
						1. Sign up your truck
					</Typography>
				</Grid>
				<Grid item xs={12} md={6}>
					<img
						src={CreateATruck}
						alt="Sign up your truck"
						style={{
							width: "50%",
							//center the image
							marginLeft: "auto",
							marginRight: "auto",
							display: "block",
						}}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={6} //center the text
					display={"flex"}
					flexDirection={"column"}
					alignItems={"center"}
					justifyContent={"center"}
					textAlign={"center"}
				>
					<Typography
						variant="h6"
						gutterBottom
						flexDirection={"column"}
						alignItems={"center"}
						justifyContent={"center"}
						textAlign={"center"}
					>
						2. Enter in your menu items and prices
					</Typography>
				</Grid>
				<Grid item xs={12} md={6}>
					<img
						src={EditBrownies}
						alt="Add A Menu Item"
						style={{
							width: "50%",
							//center the image
							marginLeft: "auto",
							marginRight: "auto",
							display: "block",
						}}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={6} //center the text
					display={"flex"}
					flexDirection={"column"}
					alignItems={"center"}
					justifyContent={"center"}
					textAlign={"center"}
				>
					<Typography
						variant="h6"
						gutterBottom
						flexDirection={"column"}
						alignItems={"center"}
						justifyContent={"center"}
						textAlign={"center"}
					>
						3. Activate your truck and start taking orders!
					</Typography>
				</Grid>
				<Grid item xs={12} md={6}>
					<img
						src={Orders}
						alt="Start taking orders!"
						style={{
							width: "50%",
							//center the image
							marginLeft: "auto",
							marginRight: "auto",
							display: "block",
						}}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					display={"flex"}
					flexDirection={"column"}
					alignItems={"center"}
					justifyContent={"center"}
					textAlign={"center"}
				>
					<Typography variant="h4" gutterBottom>
						It's that simple!
					</Typography>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							loginWithRedirect({
								//redirectURI
								authorizationParams: {
									redirect_uri: window.location.origin + "/manageTrucks",
									screen_hint: "signup",
								},
							});
						}}
					>
						Activate your truck now!
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid
					item
					xs={12}
					display={"flex"}
					flexDirection={"column"}
					alignItems={"center"}
					justifyContent={"center"}
					textAlign={"center"}
				>
					<Typography
						variant="h4"
						gutterBottom
						display={"flex"}
						flexDirection={"column"}
						alignItems={"center"}
						justifyContent={"center"}
						textAlign={"center"}
					>
						Frequently Asked Questions
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<Typography variant="h6" gutterBottom>
						How do I sign up my truck?
					</Typography>

					<Typography variant="body1" gutterBottom>
						You can sign up your truck by clicking the "Sign your truck up now!"
						button above. After you sign up, you'll be able to add your menu
						items and start taking orders.
					</Typography>

					<Typography variant="h6" gutterBottom>
						How do I activate my truck?
					</Typography>

					<Typography variant="body1" gutterBottom>
						You can activate your truck by clicking the "Activate your truck
						now!" button above. After you activate your truck, you'll be able to
						start taking orders.
					</Typography>

					<Typography variant="h6" gutterBottom>
						Does No Line Feast charge a fee?
					</Typography>

					<Typography variant="body1" gutterBottom>
						No Line Feast charges food trucks a 30 cent fee on all orders. This
						fee is used to cover the cost of processing payments and maintaining
						the platform. Further, customers are charged a 7% service fee on all
						orders.
					</Typography>

					<Typography variant="h6" gutterBottom>
						How do I get paid?
					</Typography>

					<Typography variant="body1" gutterBottom>
						No Line Feast uses Stripe to process all payments using information
						provided during sign up. Payments are made via direct deposit and
						are typically available in your bank account within 2-3 business
						days.
					</Typography>

					<Typography variant="h6" gutterBottom>
						What if I have more questions?
					</Typography>

					<Typography variant="body1" gutterBottom>
						If you have more questions, please contact us!
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
}
